import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import PasswordChangeForm from "../../components/profile/PasswordChangeForm";

const SetttingPassword = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in by checking for the accessToken in localStorage
        const accessToken = localStorage.getItem("accessToken");

        // If no accessToken is found, redirect to the login page
        if (!accessToken) {
            navigate("/login"); // Replace with the actual path to your login page
        }
    }, [navigate]);

    const handleLogout = () => {
        // Clear user data from local storage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        
        // Redirect to login page
        navigate("/login");
    };

    const handleDeactivateAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.");
        
        if (isConfirmed) {
            try {
                const token = localStorage.getItem("accessToken");
                const userId = localStorage.getItem("userId");
                
                await axios.post(`https://api.staging.sensebod.com/api/v1/users/${userId}/deactivate/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                // Clear user data from local storage
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                
                // Redirect to a deactivation confirmation page or login page
                navigate("/account-deactivated");
            } catch (error) {
                console.error("Error deactivating account:", error);
                alert("An error occurred while trying to deactivate your account. Please try again later.");
            }
        }
    };

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">
            {/* <GroupComponent emojiWavingHandSign="/-emoji-waving-hand-sign1@2x.png" /> */}
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>
            <NavBar />
            <MainNavigation
                homeClass=""
                learningClass=""
                mentorClass=""
                groupsClass=""
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />

            <div className="w-full absolute h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">

                <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Settings
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Password
                            </div>
                        </div>
                    </div>
                </div>

                <SettingHeader password="text-[#232360]" />

                <PasswordChangeForm />


                <div 
                className="absolute top-[788px] left-[285px] rounded-xl bg-buttonselect flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                onClick={handleLogout}
            >
                <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                    Log out
                </div>
            </div>
            <div 
                className="absolute top-[788px] left-[669px] rounded-xl bg-[#df4559] flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                onClick={handleDeactivateAccount}
            >
                <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                    Deactivate Account
                </div>
            </div>

            </div>


            <Rightside />


            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      
        </div>
    );
};

export default SetttingPassword;
