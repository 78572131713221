import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

const SettingHeader = ({ account = '', password, preference, mentorship, notification, privacy }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if a route is the current path
  const isActive = (path) => location.pathname === path;

  // Handlers for navigation
  const handleNavigate = (path) => () => {
    navigate(path);
  };

  return (
    <div className="absolute top-[193px] left-[289px] w-[608px] h-[21px] text-[#6a7181]">
    <div className="absolute top-[0px] left-[0px] w-[608px] h-[21px]">
        <div onClick={handleNavigate('/settings')} 
        className={`absolute top-[0px] left-[0px] font-medium cursor-pointer ${account} `}>
            Accounts
        </div>
        <div onClick={handleNavigate('/password')} 
        className={`absolute top-[0px] left-[89px] font-medium cursor-pointer ${password} `}>
            Password
        </div >
        <div  onClick={handleNavigate('/user-preference')}
        className={`absolute top-[0px] left-[180px] font-medium cursor-pointer ${preference} `}>
            Preferences
        </div>
        {/* <div className={`absolute top-[0px] left-[286px] font-medium cursor-pointer ${mentorship} `}>
            Mentorship
        </div>
        <div className={`absolute top-[0px] left-[388px] font-medium cursor-pointer ${notification} `}>
            Notification
        </div> */}
        <div className={`absolute top-[0px] left-[286px] font-medium cursor-pointer ${privacy} `}>{`Privacy & Suport`}</div>
    </div>
</div>
  );
};

SettingHeader.propTypes = {
  account: PropTypes.string,
  password: PropTypes.string,
  preference: PropTypes.string,
  mentorship: PropTypes.string,
  notification: PropTypes.string,
  privacy: PropTypes.string,
};

export default SettingHeader;
