import React, { useEffect, useState } from "react";
import MainNavigation from "../../components/MainNavigation";
import CardLearning from "../../components/learning/CardLearning";
import Hero from "../../components/learning/Hero";
import NavBar from "../../components/NavBar";
import CourseCard from "../../components/learning/CourseCard";
import Loader from "../../components/Loader";

const LearningHome = () => {
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        if (!accessToken || !userId) {
          throw new Error('Access token or user ID is missing');
        }

        // Fetch all courses
        const coursesResponse = await fetch('https://api.staging.sensebod.com/api/v1/courses/course/', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Fetch enrolled courses
        const enrolledResponse = await fetch(`https://api.staging.sensebod.com/api/v1/courses/registrant/?user=${userId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!coursesResponse.ok || !enrolledResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const coursesData = await coursesResponse.json();
        const enrolledData = await enrolledResponse.json();

        const baseImageURL = "https://api.staging.sensebod.com";

        // Format all courses
        const formattedCourses = coursesData.map((course) => ({
          id: course.id,
          title: course.name,
          description: course.description,
          slug: course.slug,
          imageUrl: course.banner
            ? `${baseImageURL}${course.banner}`
            : "/learning/courses/courses.jpg",
          rating: course.rating,
          coursePoints: course.course_points,
          instructor: course.instructor,
          registrant_count: course.registrant_count,
        }));

        // Format enrolled courses
        const formattedEnrolledCourses = enrolledData.map((registration) => ({
          id: registration.course.id,
          title: registration.course.name,
          subtitle: `${registration.registrantprogresses}`,
          imageSrc: registration.course.banner
            ? `${baseImageURL}${registration.course.banner}`
            : "/learning/courses/courses.jpg",
          lessonsInfo: `${registration.completion}/${registration.total_points} Lessons`,
          buttonText: "Resume",
          progress: registration.completion,
          notificationCount: 0, // You may need to fetch this separately if available
          studentsCount: registration.course.registrant_count,
          link: `/course/${registration.course.slug}/${registration.course.id}`
        }));

        setCourses(formattedCourses);
        setEnrolledCourses(formattedEnrolledCourses);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getFeaturedCourses = () => {
    // Filter out enrolled courses
    const unenrolledCourses = courses.filter(course =>
      !enrolledCourses.some(enrolledCourse => enrolledCourse.id === course.id)
    );

    // Sort by registrant count and get top 2
    const popularCourses = unenrolledCourses.filter(course => course.registrant_count > 0);
    popularCourses.sort((a, b) => b.registrant_count - a.registrant_count);
    return popularCourses.slice(0, 2);
  };

  if (isLoading) {
    return <div><Loader duration={10000} /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const featuredCourses = getFeaturedCourses();
  const topEnrolledCourses = enrolledCourses.slice(0, 2);
  console.log(topEnrolledCourses);

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen pb-20 overflow-x-hidden text-left text-[1rem] text-[#3b3a36] font-poppins">
      <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      <MainNavigation
        homeClass=""
        learningClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        mentorClass="custom-mentor-class"
        groupsClass="another-custom-class"
        profileClass="custom-profile-class"
      />

      <div className={`${isMobile || isTablet ? 'mt-4' : 'mt-[114px]'}`}>
        <Hero />
      </div>

      {topEnrolledCourses.length > 0 && (
        <div className={`${isMobile || isTablet ? 'px-4 mt-[26rem] ' : 'absolute top-[37.688rem] left-[17.813rem]'} w-full mq850:w-[85%] md:w-[46.25rem] h-auto`}>
          <div className="w-full flex justify-between items-center mb-4">
            <b className="tracking-[0.01em] leading-[120%]">On Going Courses</b>
            <a href="/course/mycourses">
              <img
                className="w-[1.544rem] h-[1.75rem] object-contain"
                alt=""
                src="/learning/icon1@2x.png"
              />
            </a>
          </div>

          {topEnrolledCourses.map((course, index) => (
            <CardLearning
              key={course.id}
              className={`mb-4 ${isMobile || isTablet ? 'w-full' : ''}`}
              title={course.title}
              subtitle={course.subtitle}
              imageSrc={course.imageSrc}
              lessonsInfo={course.lessonsInfo}
              buttonText={course.buttonText}
              progress={course.progress}
              notificationCount={course.notificationCount}
              studentsCount={course.studentsCount}
              link={course.link}
            />
          ))}
        </div>
      )}

      <div className={`
          ${isMobile || isTablet
          ? 'px-4 mt-8  '
          : topEnrolledCourses.length > 0
            ? 'absolute top-[68.125rem] left-[17.938rem]'
            : 'absolute top-[40rem] left-[17.938rem]'
          } 
          ${isMobile && topEnrolledCourses.length == 0 ? 'absolute top-[28rem]' : 'w-full'}
         w-full mq850:w-[90%] md:w-[46.938rem] h-auto pb-[100px]`}>
        <div className="w-full flex justify-between items-center mb-4">
          <b className="tracking-[0.01em] leading-[120%]">Featured Courses</b>
          <a href="/courses">
            <img
              className="w-[1.544rem] h-[1.75rem] object-contain cursor-pointer"
              alt=""
              src="/learning/icon1@2x.png"
            />
          </a>
        </div>
        {/* <h2 className="text-2xl font-bold mb-6"><br></br></h2> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {featuredCourses.length > 0 ? (
            featuredCourses.map((course) => (
              <CourseCard key={course.id} course={course} />
            ))
          ) : (
            <p className="col-span-full text-center text-gray-600">
              No featured courses available.
            </p>
          )}
        </div>
      </div>


    </div>
  );
};

export default LearningHome;