import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

const PasswordChangeForm = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSave = () => {
    // Implement save logic here
    console.log('Saving new password');
    setIsEditing(false);
  };

  const togglePasswordVisibility = (setter) => {
    setter(prev => !prev);
  };

  const renderPasswordField = (label, value, onChange, showPassword, setShowPassword) => (
    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="relative tracking-[-0.01em] leading-[20px] text-xs text-[#939699]">
          {label}
        </div>
      </div>
      <div className="self-stretch rounded-[16px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[56px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[16px] px-[20px]">
        <div className="flex flex-row items-center justify-start gap-[12px]">
          <img
            className="w-[20px] relative h-[20px] overflow-hidden shrink-0"
            alt=""
            src="/profile/password.svg"
          />
          <input
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={!isEditing}
            className="bg-transparent border-none outline-none text-3xs tracking-[-0.03em] leading-[20px] font-medium w-full"
            placeholder="Enter Password"
          />
        </div>
        <button
          onClick={() => togglePasswordVisibility(setShowPassword)}
          className="bg-transparent border-none cursor-pointer"
        >
          {showPassword ? (
            <EyeOff className="w-[20px] h-[20px] text-gray-500" />
          ) : (
            <Eye className="w-[20px] h-[20px] text-gray-500" />
          )}
        </button>
      </div>
    </div>
  );

  return (
    <div className="absolute top-[262px] left-[382px] w-[511px] h-[408px] text-buttonselect">
      {!isEditing ? (
        <div className="absolute top-[0px] left-[463px] rounded-[5px] bg-background-main w-[60px] h-[25px] cursor-pointer" onClick={handleEdit}>
          <div className="absolute top-[3px] left-[11px] w-[38px] h-[20px]">
            <img
              className="absolute top-[3px] left-[0px] w-[14px] h-[14px] overflow-hidden"
              alt=""
              src="/profile/edit.svg"
            />
            <div className="absolute top-[0px] left-[18px] tracking-[-0.01em] leading-[20px] font-medium">
              Edit
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute top-[0px] left-[318px] flex flex-row gap-[17px]">
          <div className="rounded-xl bg-default-white w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border cursor-pointer" onClick={handleCancel}>
            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
              Cancel
            </div>
          </div>
          <div className="rounded-xl bg-buttonselect w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border text-default-white cursor-pointer" onClick={handleSave}>
            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
              Save
            </div>
          </div>
        </div>
      )}
      <div className="absolute top-[73px] left-[calc(50%_-_261.5px)] w-[504px] flex flex-col items-start justify-start gap-[16px]">
        {renderPasswordField("Old password", oldPassword, setOldPassword, showOldPassword, setShowOldPassword)}
        {renderPasswordField("New password", newPassword, setNewPassword, showNewPassword, setShowNewPassword)}
        {renderPasswordField("Confirm new password", confirmPassword, setConfirmPassword, showConfirmPassword, setShowConfirmPassword)}
      </div>
    </div>
  );
};

export default PasswordChangeForm;