import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Hero = ({ className = "" }) => {
  const navigate = useNavigate();
  return (
    <div className={`w-full md:w-[740px] mx-auto px-4 md:px-0 mt-8 md:mt-0 md:absolute mq850:absolute mq850:top-[20px] md:top-[114px] md:left-[285px] text-[#3b3a36] font-poppins ${className}`}>
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
          Learning
        </h1>
      </div>

      <div className="relative w-full h-[300px] md:h-[242px] mq850:hidden mb-8 md:mb-0 ">
        <div className="absolute inset-0 rounded-xl bg-background-main overflow-hidden text-center text-sm text-default-white font-body-xs">
          <div className="absolute bottom-0 left-0 right-0 bg-background-main h-[128px] px-4 md:px-0">
            <div className="relative w-full md:w-[565px] h-[8px] mx-auto mt-4 md:mt-[83px]">
              <div className="absolute top-0 left-0 right-0 rounded-31xl bg-black h-[8px]" />
              <div className="absolute top-0 left-0 rounded-31xl bg-[#161514] w-[0.8px] h-[8px]" />
            </div>
            <b className="absolute bottom-4 right-4 md:bottom-[26px] md:right-[95px]">Novice</b>
            <div className="absolute bottom-4 left-4 md:bottom-[37px] md:left-[38px] w-[30px] h-[26.5px] text-3xs">
              <img
                className="absolute top-0 left-0 w-[30px] h-[26.5px]"
                alt=""
                src="/learning/rectangle-167.svg"
              />
              <div className="absolute top-[11px] left-[10px]">0%</div>
            </div>
            <img
              className="absolute bottom-16 right-4 md:bottom-[27px] md:right-[37px] w-[71px] h-[71px]"
              alt=""
              src="/learning/frame-1171275991.svg"
            />
          </div>
        </div>
        <img
          className="absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 md:w-[391px] h-auto md:h-[275.3px] object-contain"
          alt=""
          src="/learning/illustration@2x.png"
        />
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-[54px] mq850:w-[85%] top-[20px] relative">
        <div className="w-full md:w-[343px] h-[88px] rounded-[8px] bg-[rgba(5,93,178,0.49)] border-[#cdcdcd] border border-solid flex items-center p-4">
          <img
            className="w-[78px] h-[72px] object-cover mr-4"
            alt=""
            src="/learning/stuck-at-home-to-do-list@2x.png"
          />
          <div className="flex-grow cursor-pointer" onClick={() => navigate("/course/quiz-ai")}>
            <div className="tracking-[-0.5px] leading-[26px] font-semibold mb-2">
              General Quizzes
            </div>
            {/* <div className="w-full h-[11px] bg-[#fff5ee] rounded-[16px] relative">
              <div className="absolute h-full w-[40.54%] top-0 left-0 rounded-mini border-[#fff5ee] border border-solid" />
            </div> */}
          </div>
        </div>

        <div className="w-full md:w-[343px] h-[88px] rounded-[8px] bg-[rgba(20,184,185,0.5)] border-[#cdcdcd] border border-solid flex items-center p-4">
          <img
            className="w-[78px] h-[72px] object-cover mr-4"
            alt=""
            src="/learning/hands-graduate@2x.png"
          />
          <div className="flex-grow">
            <div className="tracking-[-0.5px] leading-[26px] font-semibold mb-2">
              Past Questions Quizzes
            </div>
            {/* <div className="w-full h-[11px] bg-[#fff5ee] rounded-[16px] relative">
              <div className="absolute h-full w-[40.54%] top-0 left-0 rounded-mini border-[#fff5ee] border border-solid" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;