import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

const AllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [enrolledCourseIds, setEnrolledCourseIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCoursesAndEnrollments = async () => {
      try {
        setLoading(true);
        const userId = localStorage.getItem("userId");
        const accessToken = localStorage.getItem("accessToken");

        // Fetch all courses
        const coursesResponse = await fetch(
          "https://api.staging.sensebod.com/api/v1/courses/course/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Fetch enrolled courses
        const enrolledResponse = await fetch(
          `https://api.staging.sensebod.com/api/v1/courses/registrant/?user=${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (coursesResponse.ok && enrolledResponse.ok) {
          const coursesData = await coursesResponse.json();
          const enrolledData = await enrolledResponse.json();

          const baseImageURL = "https://api.staging.sensebod.com";
          const formattedCourses = coursesData.map(course => ({
            id: course.id,
            title: course.name,
            description: course.description,
            slug: course.slug,
            imageUrl: course.banner
              ? `${baseImageURL}${course.banner}`
              : "/learning/courses/courses.jpg",
            rating: course.rating,
            coursePoints: course.course_points,
            instructor: course.instructor,
          }));

          const enrolledIds = enrolledData.map(enrollment => enrollment.course.id);

          setCourses(formattedCourses);
          setEnrolledCourseIds(enrolledIds);
          setLoading(false);
        } else {
          console.error("Failed to fetch courses or enrollments");
        }
      } catch (error) {
        console.error("Error fetching courses and enrollments:", error);
        setLoading(false);
      }
    };

    fetchCoursesAndEnrollments();
  }, []);

  const filteredCourses = courses.filter((course) =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCourseAction = (slug, id) => {
    if (enrolledCourseIds.includes(id)) {
      navigate(`/course/startcourse/${slug}/${id}`);
    } else {
      navigate(`/course/${slug}/${id}`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto py-4 px-4 md:py-8 md:px-0 mq850:w-[90%]">
      <div className="flex justify-center mb-6">
        <input
          type="text"
          className="border border-gray-300 rounded-lg px-4 py-2 w-full max-w-lg focus:outline-none focus:border-primary-gold"
          placeholder="Search for courses..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course) => (
            <div
              key={course.id}
              className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-4"
            >
              <img
                className="w-full h-48 object-cover rounded-t-lg"
                src={course.imageUrl}
                alt={course.title}
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                <p className="text-gray-600 text-sm">{course.description}</p>
                <div className="text-sm text-gray-500 mt-2 flex items-center">
                  <span className="flex items-center">
                    {course.rating}
                    <img
                      className="h-[25px] w-[25px] ml-1"
                      alt=""
                      src="/learning/group2.svg"
                    />
                  </span>
                  <span className="flex items-center ml-4">
                    {course.coursePoints}
                    <img
                      className="h-[25px] w-[25px] ml-1"
                      alt=""
                      src="/learning/group.svg"
                    />
                  </span>
                </div>
                
                <button
                  className="mt-4 w-full bg-primary-gold text-white px-4 py-2 rounded-lg hover:bg-primary-gold-dark transition-colors duration-300 cursor-pointer"
                  onClick={() => handleCourseAction(course.slug, course.id)}
                >
                  {enrolledCourseIds.includes(course.id) ? "Resume" : "View Course"}
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="col-span-full text-center text-gray-600">
            No courses found.
          </p>
        )}
      </div>
    </div>
  );
};

export default AllCourses;