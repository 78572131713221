import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faBell, faSignOutAlt, faUserCog } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ className = "" }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const username = localStorage.getItem("username");

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (response.ok) {
        // Logout successful
        localStorage.clear();
        navigate("/");
      } else {
        // Handle logout failure
        console.error("Logout failed:", await response.text());
        // Optionally, you can still clear local storage and redirect
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      // In case of network errors, still clear local storage and redirect
      localStorage.clear();
      navigate("/");
    }
  };

  const handleSettings = () => {
    console.log("Navigate to settings");
  };

  return (
    <div className={`relative ${className}`}>
      <div className="absolute top-[41px] w-full h-[50px]">
        {/* Mobile-specific content */}
        <div className={`${isMobile ? 'block' : 'hidden'} w-full px-4`}>
          {/* Total XP component - now at the top right for mobile only */}
          <div className="absolute top-[-25px] right-4 w-[115px] h-[50px] text-xs text-black">
            <div className="absolute top-[0px] left-[0px] rounded-[10px] bg-default-white border-[#df4559] border-[0.5px] border-solid box-border w-[115px] h-[48px]" />
            <div className="absolute top-[3px] left-[34px]">Total XP</div>
            <b className="absolute top-[17px] left-[calc(50%_-_6.5px)] text-3xl text-transparent !bg-clip-text [background:linear-gradient(46.25deg,_#df4559,_#f09a19)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
              0
            </b>
          </div>

          <div className="absolute w-[calc(100%_-_354.6px)] left-[70px] top-[calc(50%_-_52px)] flex flex-row items-center justify-start mq1300:gap-[20px] gap-[100px] text-left text-base text-[#211c37] font-poppins">
            <div className="flex flex-col items-start justify-center gap-[7px]">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <div className="relative font-semibold">Hey, {username}</div>
                <img
                  className="w-[26.4px] relative h-[25.7px] object-contain"
                  alt=""
                  src={`${baseUrl}/dashboard/-emoji-waving-hand-sign@2x.png`}
                />
              </div>
              <div className="w-[170px] relative h-[10px]">
                <div className="absolute h-full w-full rounded-[24px] bg-background-main" />
                <div className="absolute h-full w-[10%] rounded-[24px] bg-primary-gold" />
              </div>
            </div>
          </div>
        </div>

        {/* Desktop content */}
        <div className={`${isMobile ? 'hidden' : 'block'}`}>
          <div className="absolute w-[calc(100%_-_354.6px)] left-[285px] top-[calc(50%_-_25px)] flex flex-row items-center justify-start mq1300:gap-[20px] gap-[100px] text-left text-base text-[#211c37] font-poppins">
            <div className="flex flex-col items-start justify-start gap-[7px]">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <div className="relative font-semibold">Hey, {username} Sensebod</div>
                <img
                  className="w-[26.4px] relative h-[25.7px] object-contain"
                  alt=""
                  src={`${baseUrl}/dashboard/-emoji-waving-hand-sign@2x.png`}
                />
              </div>
              <div className="w-[208px] relative h-[10px]">
                <div className="absolute h-full w-full rounded-[24px] bg-background-main" />
                <div className="absolute h-full w-[10%] rounded-[24px] bg-primary-gold" />
              </div>
            </div>
          </div>

          <div className="absolute top-[calc(50%_-_17px)] right-[90px] h-[34px] flex items-center space-x-14">
            {/* Total XP component for desktop */}
            <div className="w-[115px] relative h-[50px] text-xs text-black">
              <div className="absolute top-[0px] left-[0px] rounded-[10px] bg-default-white border-[#df4559] border-[0.5px] border-solid box-border w-[115px] h-[48px]" />
              <div className="absolute top-[3px] left-[34px]">Total XP</div>
              <b className="absolute top-[17px] left-[calc(50%_-_6.5px)] text-3xl text-transparent !bg-clip-text [background:linear-gradient(46.25deg,_#df4559,_#f09a19)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                0
              </b>
            </div>

            {/* Notification Icon with Badge */}
            <div className="relative flex items-center">
              <FontAwesomeIcon
                icon={faBell}
                className="w-[24px] h-[24px] text-gray-600 cursor-pointer"
              />
              <div className="absolute w-[10px] h-[10px] rounded-full bg-red-500 top-0 right-0 flex items-center justify-center text-xs text-white">
                1
              </div>
            </div>

            {/* Chat Icon */}
            <img
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              alt=""
              src={`${baseUrl}/dashboard/chatoutline.svg`}
            />

            {/* Badge Icon */}
            <img
              className="w-[24px] h-[24px] object-contain cursor-pointer"
              alt=""
              src={`${baseUrl}/dashboard/badgelabelsvgrepocom-1.svg`}
            />

            {/* Settings Icon */}
            <FontAwesomeIcon
              icon={faCog}
              className="w-[24px] h-[24px] text-gray-600 cursor-pointer"
              onClick={toggleDropdown}
            />

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-[180px] bg-[#000] rounded-lg shadow-lg z-50 border border-gray-200">
                <div
                  className="py-2 px-4 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={handleSettings}
                >
                  <FontAwesomeIcon icon={faUserCog} className="w-5 h-5 mr-2" />
                  <span>Settings</span>
                </div>
                <div
                  className="py-2 px-4 hover:bg-red-100 hover:text-red-500 cursor-pointer flex items-center"
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="w-5 h-5 mr-2" />
                  <span>Logout</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

export default NavBar;