import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen = () => {
  const navigate = useNavigate();

  const [notificationOptions, setNotificationOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  // Function to fetch notification preferences from the API
  const fetchNotificationPreferences = async () => {
    try {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/notification-preference/",
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        }
      );
      setNotificationOptions(response.data); // Assuming the API returns an array of notification preferences
      console.log("Notification Preferences", response.data);
    } catch (error) {
      console.error("Error fetching notification preferences:", error);
    }
  };

  useEffect(() => {
    fetchNotificationPreferences();
  }, []); // Fetch notification preferences when the component mounts

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected notification option to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      notification_preference: selectedOption,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/14-preferencesscreen");
  }, [selectedOption, navigate]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/6-preferencesscreen");
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.value); // Use the value as the selected option
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Choose your notification preferences and stay motivated.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="90%"
          rectangleDivRight="10%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          9 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <form className="absolute top-[300px] left-[calc(50%_-_385px)] w-[770px] flex flex-wrap gap-[16px]">
        {notificationOptions.map((option, index) => (
          <div
            key={index}
            onClick={() => handleOptionSelect(option)}
            className={`cursor-pointer p-4 rounded-xl border-[2px] border-solid box-border text-left w-[370px] ${
              selectedOption === option.value
                ? "bg-green-200 border-[#EFEFFC]"
                : "bg-white border-[#EFEFFC]"
            }`}
          >
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <div className="tracking-[-0.03em] leading-[20px] font-semibold">
                {option.display_name} {/* Displaying display_name */}
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default PreferencesScreen;
