import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../components/Input";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import axios from "axios";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get("redirect") || "/home";

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://api.staging.sensebod.com/api/v1/login/", {
        username,
        password,
      });

      if (response.status === 200) {
        const { access, refresh, user_id } = response.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("userId", user_id);
        localStorage.setItem("username", username);
        navigate(redirectPath);
      }
    } catch (error) {
      setError(error.response?.data?.detail || "Login failed. Please check your username and password.");
      console.error("Error logging in:", error);
    }
  };

  const handleSignupClick = () => navigate("/signup");

  return (
    <div className="flex flex-col md:flex-none items-center justify-center w-full min-h-screen md:h-screen bg-default-white text-base text-buttonselect font-poppins md:relative">
      {/* Mobile view */}
      <div className="w-full px-4 py-8 flex flex-col items-center md:hidden">
        <h2 className="text-3xl font-bold mb-8">Welcome Back!</h2>
        <form onSubmit={handleSubmit} className="w-full max-w-[370px]">
          <div className="mb-4">
            <Input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Your username"
              inputWidth="90%"
              icon="/icon2.svg"
              iconWidth="24px"
              iconHeight="24px"
            />
          </div>
          <div className="mb-4">
            <Input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Your password"
              inputWidth="90%"
              icon="/icon3.svg"
              iconWidth="24px"
              iconHeight="24px"
            />
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <div className="flex justify-between items-center mb-4">
            <Checkbox
              showLabel
              checkboxHeight="40px"
              checkboxPadding="8px 0px"
              checkboxGap="8px"
              boxWidth="24px"
              boxBorder="1px solid #055db2"
              boxHeight="24px"
              smallLabelMedium="Stay logged in"
              smallLabelFontSize="12px"
              smallLabelFontFamily="Poppins"
              smallLabelColor="#9e9c9a"
            />
            <b className="text-xs text-primary-gold">I forgot my password</b>
          </div>
          <Button
            buttonWidth="100%"
            buttonBackgroundColor="#055db2"
            buttonPadding="16px"
            button="Login"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            type="submit"
          />
        </form>
        <div className="mt-8 w-full max-w-[370px]">
          <div className="flex items-center justify-center mb-4">
            <div className="border-t border-[#e6e6e6] flex-grow"></div>
            <div className="mx-4 text-[#858494]">OR</div>
            <div className="border-t border-[#e6e6e6] flex-grow"></div>
          </div>
          <div className="flex justify-center space-x-4 mb-8">
            <button className="w-[54px] h-[54px] rounded-full bg-default-white shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex items-center justify-center">
              <img className="w-[40.5px] h-[40.5px] object-cover" alt="" src="/image-1@2x.png" />
            </button>
            <img className="w-[53px] h-[54px]" alt="" src="/group-91.svg" />
          </div>
          <p className="text-center text-xs text-[#9e9c9a]">
            By continuing, you agree to the{' '}
            <span className="font-medium text-primary-gold">Terms of Services</span>
            {' & '}
            <span className="font-medium text-primary-gold">Privacy Policy</span>.
          </p>
        </div>
        <div className="mt-8">
          <Button
            button="Signup"
            buttonWidth="200px"
            buttonBackgroundColor="#d9d9d9"
            buttonTextColor="#055DB2"
            buttonPadding="12px"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={handleSignupClick}
          />
        </div>
      </div>

      {/* Desktop view (unchanged) */}
      <div className="hidden md:block absolute top-[-5px] w-full h-[947px]">
        <img
          className="absolute top-[-100px] right-[10px] w-[821.9px] h-[821.9px] object-contain"
          alt=""
          src="/easystem-4-11@2x.png"
        />
        <img
          className="absolute top-[208px] right-[calc(50%_-_651px)] w-[801px] h-[739px] object-contain"
          alt=""
          src="/bg.png"
        />
        <div className="absolute top-[36px] opacity-[0.5] right-[20px]">
          <Button
            button="Signup"
            buttonWidth="100px"
            buttonBackgroundColor="#d9d9d9"
            buttonTextColor="#055DB2" 
            buttonPadding="12px"
            buttonFontSize="16px"
            buttonFontWeight="bold"
            buttonFontFamily="Poppins"
            onButtonContainerClick={handleSignupClick}
          />
        </div>
        <img
          className="absolute top-[160px] right-[420px] w-[300px] h-[90.7px] overflow-hidden"
          alt=""
          src="/sensebody-secondary-logo--1-1.svg"
        />
      </div>
      <div className="hidden md:block absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] text-left text-sm text-default-white">
        <div className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px]">
          <div className="absolute top-[317px] left-[399px] bg-background-main w-[581px] h-[100vh]" />
          <img
            className="absolute top-[0px] left-[0px] w-[1276.7px] h-[1305.2px] object-contain"
            alt=""
            src="/o14@2x.png"
          />
          <b className="absolute top-[397px] left-[calc(50%_-_109.35px)] text-[40px]">
            Welcome Back!
          </b>

          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Your username"
              inputPosition="absolute"
              inputPadding="16px"
              inputGap="16px"
              inputBottom="719.2px"
              inputLeft="calc(50% - 139.35px)"
              inputWidth="370px"
              icon="/icon2.svg"
              iconWidth="24px"
              iconHeight="24px"
            />

            <Input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Your password"
              icon="/icon3.svg"
              inputPosition="absolute"
              inputPadding="16px"
              inputGap="16px"
              inputBottom="640px"
              inputLeft="calc(50% - 139.35px)"
              inputWidth="370px"
              iconWidth="24px"
              iconHeight="24px"
            />

            {error && (
              <div className="absolute top-[467px] left-[calc(50%_-_109.35px)] text-red-500">
                {error}
              </div>
            )}

            <b className="absolute top-[807px] left-[calc(50%_-_3.35px)] text-3xs text-primary-gold">
              I forgot my password
            </b>

            <Button
              buttonWidth="370px"
              buttonPosition="absolute"
              buttonBackgroundColor="#055db2"
              buttonPadding="16px 133px"
              buttonBottom="unset"
              buttonLeft="calc(50% - 137.35px)"
              buttonTop="736px"
              button="Login"
              buttonFontSize="16px"
              buttonFontWeight="bold"
              buttonFontFamily="Poppins"
              type="submit"
            />
          </form>

          <div className="absolute top-[843px] left-[501px] w-[377px] h-[24px] text-center text-base text-[#858494]">
            <div className="absolute top-[0px] left-[175.2px] leading-[150%] flex items-center justify-center w-[26.5px]">
              OR
            </div>
            <div className="absolute top-[12px] left-[220.2px] border-[#e6e6e6] border-t-[1px] border-solid box-border w-[157.8px] h-[1px]" />
            <div className="absolute top-[12px] left-[0px] border-[#e6e6e6] border-t-[1px] border-solid box-border w-[156.6px] h-[1px]" />
          </div>
          <div className="absolute top-[898px] left-[614px] w-[152px] h-[55px]">
            <div className="absolute top-[0px] left-[0px] w-[152px] h-[55px]">
              <div className="absolute top-[1px] left-[0px] w-[54px] h-[54px]">
                <div className="absolute top-[0px] left-[0px] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] rounded-[50%] bg-default-white w-[54px] h-[54px]" />
                <img
                  className="absolute top-[6.8px] left-[6.8px] w-[40.5px] h-[40.5px] object-cover"
                  alt=""
                  src="/image-1@2x.png"
                />
              </div>
              <img
                className="absolute top-[0px] left-[99px] w-[53px] h-[54px]"
                alt=""
                src="/group-91.svg"
              />
            </div>
          </div>
          <div className="absolute top-[1007px] left-[526px] leading-[140%] text-center flex items-center w-[327px] font-poppins">
            <span className="w-full">
              <span>
                <span>By continuing, you agree to the</span>
                <span className="font-poppins">{` `}</span>
              </span>
              <span className="font-medium text-primary-gold">
                Terms of Services
              </span>
              <span className="font-poppins">
                <span className="font-medium">{` `}</span>
                <span className="font-poppins">{`&`}</span>
                <span className="font-medium font-poppins">{` `}</span>
              </span>
              <span className="font-medium text-primary-gold">
                Privacy Policy
              </span>
              <span className="font-poppins">.</span>
            </span>
          </div>
          <div className="absolute top-[678px] left-[501px] w-[330px] flex flex-row items-center justify-start">
            <Checkbox
              showLabel
              checkboxPosition="unset"
              checkboxHeight="40px"
              checkboxPadding="8px 0px"
              checkboxGap="8px"
              checkboxFlex="1"
              boxWidth="24px"
              boxBorder="1px solid #fff"
              boxHeight="24px"
              smallLabelMedium="Stay logged in"
              smallLabelFontSize="12px"
              smallLabelFontFamily="Poppins"
              smallLabelColor="#9e9c9a"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;