import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import SettingHeader from "../../components/profile/SettingHeader";
import Loader from "../../components/Loader";

const Settting = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            navigate("/login");
        } else {
            fetchUserData(accessToken);
        }
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserData(response.data);
            setEditedData(response.data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedData(userData);
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            const updatedData = {
                first_name: editedData.first_name,
                last_name: editedData.last_name,
                email: editedData.email,
                phone_number: editedData.phone_number,
                date_of_birth: editedData.date_of_birth,
                school: {
                    ...userData.school,
                    school_name: editedData.school.school_name
                }
            };

            const response = await axios.patch(`https://api.staging.sensebod.com/api/v1/users/${userId}/`, updatedData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setUserData(response.data);
            setEditedData(response.data);
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating user data:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "school") {
            setEditedData(prevState => ({
                ...prevState,
                school: {
                    ...prevState.school,
                    school_name: value
                }
            }));
        } else {
            setEditedData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleLogout = () => {
        // Clear user data from local storage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        
        // Redirect to login page
        navigate("/login");
    };

    const handleDeactivateAccount = async () => {
        const isConfirmed = window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.");
        
        if (isConfirmed) {
            try {
                const token = localStorage.getItem("accessToken");
                const userId = localStorage.getItem("userId");
                
                await axios.post(`https://api.staging.sensebod.com/api/v1/users/${userId}/deactivate/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                // Clear user data from local storage
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                
                // Redirect to a deactivation confirmation page or login page
                navigate("/account-deactivated");
            } catch (error) {
                console.error("Error deactivating account:", error);
                alert("An error occurred while trying to deactivate your account. Please try again later.");
            }
        }
    };

    if (!userData) return <Loader duration={10000} />;

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">


            {/* <GroupComponent emojiWavingHandSign="/-emoji-waving-hand-sign1@2x.png" /> */}
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>

            <div className="w-full absolute h-[973px] overflow-hidden text-left text-sm text-default-white font-poppins">
                <NavBar />
                <MainNavigation
                    homeClass=""
                    learningClass=""
                    mentorClass=""
                    groupsClass=""
                    profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
                />
                <div className="absolute top-[122px] left-[285px] flex flex-row items-start justify-start gap-[9px] text-[#92929d] font-menu-menu-1-bold">
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Profiles
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Settings
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[8px]">
                        <div className="w-[16px] relative h-[16px]">
                            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[2px] opacity-[0]" />
                            <img
                                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full"
                                alt=""
                                src="/profile/16--chevronright.svg"
                            />
                        </div>
                        <div className="flex flex-row items-center justify-center">
                            <div className="relative tracking-[0.01em] leading-[130%] font-semibold">
                                Account Details
                            </div>
                        </div>
                    </div>
                </div>

                <SettingHeader account="text-[#232360]" />

                <div className="absolute top-[281px] left-[304px] w-[589px] h-[403px] text-xs text-[#939699]">
                    <div className="absolute top-[0px] left-[0px] w-[589px] h-[403px]">
                        <div className="absolute top-[153px] left-[0px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                            {/* Full name */}
                            <div className="self-stretch flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">
                                            Full name
                                        </div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                        {isEditing ? (
                                            <div className="flex w-full">
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    value={editedData.first_name || ''}
                                                    onChange={handleInputChange}
                                                    className="w-1/2 bg-transparent mr-2"
                                                />
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    value={editedData.last_name || ''}
                                                    onChange={handleInputChange}
                                                    className="w-1/2 bg-transparent"
                                                />
                                            </div>
                                        ) : (
                                            <div className="relative tracking-[-0.03em] leading-[20px] font-semibold">
                                                {userData.first_name} {userData.last_name}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Age */}
                            <div className="self-stretch flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">
                                            Age
                                        </div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                        {isEditing ? (
                                            <input
                                                type="date"
                                                name="date_of_birth"
                                                value={editedData.date_of_birth || ''}
                                                onChange={handleInputChange}
                                                className="w-full bg-transparent"
                                            />
                                        ) : (
                                            <div className="relative tracking-[-0.03em] leading-[20px] font-semibold">
                                                {userData.date_of_birth ? new Date().getFullYear() - new Date(userData.date_of_birth).getFullYear() : 'N/A'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Country */}
                            <div className="w-[274px] flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">
                                            Country
                                        </div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px]">
                                        <div className="relative tracking-[-0.03em] leading-[20px]">
                                            {userData.school.country.country || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute top-[153px] left-[315px] w-[274px] flex flex-col items-start justify-start gap-[17px]">
                            {/* Email */}
                            <div className="self-stretch flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">{`Email `}</div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm">
                                        {isEditing ? (
                                            <input
                                                type="email"
                                                name="email"
                                                value={editedData.email || ''}
                                                onChange={handleInputChange}
                                                className="w-full bg-transparent"
                                            />
                                        ) : (
                                            <div className="relative tracking-[-0.03em] leading-[20px]">
                                                {userData.email || 'N/A'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Phone number */}
                            <div className="self-stretch flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">
                                            Phone number
                                        </div>
                                    </div>
                                    <div className="self-stretch rounded-[16px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-start py-[16px] pl-[16px] pr-[95px] gap-[9px] text-[#686b6d]">
                                        {isEditing ? (
                                            <input
                                                type="tel"
                                                name="phone_number"
                                                value={editedData.phone_number || ''}
                                                onChange={handleInputChange}
                                                className="w-full bg-transparent"
                                            />
                                        ) : (
                                            <div className="relative tracking-[-0.03em] leading-[20px]">
                                                {userData.phone_number || 'N/A'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* School */}
                            <div className="w-[274px] flex flex-col items-start justify-start">
                                <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                                    <div className="self-stretch flex flex-row items-center justify-start">
                                        <div className="relative tracking-[-0.01em] leading-[20px]">
                                            School
                                        </div>
                                    </div>
                                    <div className="self-stretch rounded-[12px] bg-[#f5f5f5] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-[#686b6d]">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                name="school"
                                                value={editedData.school.school_name || ''}
                                                onChange={handleInputChange}
                                                className="w-full bg-transparent"
                                            />
                                        ) : (
                                            <div className="relative tracking-[-0.03em] leading-[20px]">
                                                {userData.school.school_name || 'N/A'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img
                            className="absolute top-[0px] left-[220px] w-[110px] h-[128px] object-contain"
                            alt=""
                            src="/profile/square@2x.png"
                        />
                    </div>
                    {isEditing ? (
                    <div className="absolute top-[-33px] left-[400px] flex flex-row gap-[17px]">
                        <div className="rounded-xl bg-default-white w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border cursor-pointer" onClick={handleCancel}>
                            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                Cancel
                            </div>
                        </div>
                        <div className="rounded-xl bg-buttonselect w-[88px] flex flex-row items-center justify-center py-[12px] px-[0px] box-border text-default-white cursor-pointer" onClick={handleSave}>
                            <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                                Save
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="absolute top-[-33px] left-[529px] rounded-[5px] bg-background-main w-[60px] h-[25px] text-[11px] text-default-white cursor-pointer" onClick={handleEdit}>
                        <div className="absolute top-[3px] left-[11px] w-[38px] h-[20px]">
                            <img
                                className="absolute top-[3px] left-[0px] w-[14px] h-[14px] overflow-hidden"
                                alt=""
                                src="/profile/edit.svg"
                            />
                            <div className="absolute top-[0px] left-[18px] tracking-[-0.01em] leading-[20px] font-medium">
                                Edit
                            </div>
                        </div>
                    </div>
                )}
                </div>
                <div 
                className="absolute top-[788px] left-[285px] rounded-xl bg-buttonselect flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                onClick={handleLogout}
            >
                <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                    Log out
                </div>
            </div>
            <div 
                className="absolute top-[788px] left-[669px] rounded-xl bg-[#df4559] flex flex-row items-center justify-center py-[12px] px-[50px] cursor-pointer"
                onClick={handleDeactivateAccount}
            >
                <div className="relative tracking-[-0.01em] leading-[20px] font-semibold">
                    Deactivate Account
                </div>
            </div>

            </div>


            <Rightside />


            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
                <img
                    className="w-[75px] h-[75px] object-contain cursor-pointer"
                    alt="AI Chat"
                    src="/home/AIChart.svg"
                />
            </a>

        </div>
    );
};

export default Settting;
