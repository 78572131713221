import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import EmojiPicker from "emoji-picker-react";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import GroupSetting from "../../components/groups/GroupSetting";
import Loader from "../../components/Loader";
import Leftside from "../../components/groups/Leftside";
import RightSide from "../../components/groups/RightSide";

// Main GroupsChat Component
const GroupsChat = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [showGroupSetting, setShowGroupSetting] = useState(false);
  const [groups, setGroups] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleInfoClick = () => {
    setShowGroupSetting(!showGroupSetting);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    navigate(`/group/groups-chat/${group.uuid}`);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(
          "https://api.staging.sensebod.com/api/v1/groups/group/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const userId = localStorage.getItem("userId");

          const userGroups = data.filter(group =>
            group.members.some(member => member.member.user_id === userId)
          );

          // Fetch last messages for each group
          const groupsWithLastMessages = await Promise.all(userGroups.map(async (group) => {
            const lastMessage = await fetchLastMessage(group.uuid);
            return { ...group, lastMessage };
          }));

          setGroups(groupsWithLastMessages);
          fetchUsernames(groupsWithLastMessages);

          // Find the group that matches the URL's groupId
          const groupFromUrl = userGroups.find((group) => group.uuid === groupId);
          if (groupFromUrl) {
            setSelectedGroup(groupFromUrl);
          } else if (userGroups.length > 0 && !selectedGroup) {
            // Only set the first group if no group is currently selected
            setSelectedGroup(userGroups[0]);
            navigate(`/group/groups-chat/${userGroups[0].uuid}`);
          }
        } else {
          console.error("Failed to fetch groups:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, [groupId, navigate, selectedGroup]);

  const fetchLastMessage = async (groupId) => {
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/message/?forum=${forumId}&limit=1`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Last Message", data);
        return data.results[0]?.content || "";
      } else {
        console.error("Failed to fetch last message:", response.statusText);
        return "";
      }
    } catch (error) {
      console.error("Error fetching last message:", error);
      return "";
    }
  };

  const fetchUsernames = async (groups) => {
    const usernamesMap = { ...usernames };

    console.log("UsernameGroup", groups);

    for (const group of groups) {
      if (group.created_by.user_id && !usernamesMap[group.created_by]) {
        try {
          const response = await fetch(
            `https://api.staging.sensebod.com/api/v1/users/${group.created_by.user_id}/`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          if (response.ok) {
            const userData = await response.json();
            usernamesMap[group.created_by] = userData.username;
          } else {
            console.error(
              `Failed to fetch username for user ID: ${group.created_by}`,
              response.statusText
            );
            usernamesMap[group.created_by] = "Unknown";
          }
        } catch (error) {
          console.error("Error fetching username:", error);
          usernamesMap[group.created_by] = "Unknown";
        }
      }
    }

    setUsernames(usernamesMap);
  };

  if (!selectedGroup) {
    return (
      <div className="p-4">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-hidden text-center text-xs md:text-[6px] text-default-white font-poppins">
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass=""
        groupsClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        profileClass="custom-profile-class"
      />
      <div className="flex flex-col md:flex-row px-4 md:px-0 mt-20 md:mt-0">
        <div className="w-full md:w-[300px] mb-6 md:mb-0">
          <div className="flex justify-between items-center mb-4 md:mb-0 md:block">
            <div className="flex space-x-4 md:space-x-0 md:block">
              <div className="flex items-center md:absolute md:top-[135px] md:left-[290px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[20px]">Groups</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[370px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">{selectedGroup.group_type}</p>
              </div>
              <div className="flex items-center md:absolute md:top-[135px] md:left-[455px]">
                <img className="w-4 h-4 md:w-[16px] md:h-[16px]" alt="" src="/mentor/Icon (3).svg" />
                <p className="ml-2 text-[#92929D] text-sm font-semibold md:absolute md:top-[-14px] md:left-[25px]">{selectedGroup.name}</p>
              </div>
            </div>
          </div>

          


          {!showGroupSetting && (
            <div className="w-full md:w-[300px] mb-6 md:mb-0">
            <Leftside
              className="custom-class"
              groups={groups.map((group) => ({
                ...group,
                creator: group.created_by.username || "",
                lastMessage: group.lastMessage,
              }))}
              onGroupClick={handleGroupClick}
              selectedGroupId={selectedGroup?.uuid}
            />
          </div>
          )}
        </div>

        <div
          className={`absolute top-[193px] h-[635px] w-[654px] ${showGroupSetting ? "left-[289px]" : "left-[580px]"
            }`}
        >
          {selectedGroup && (
            <RightSide
              selectedGroup={{
                ...selectedGroup,
                id: selectedGroup.uuid,
                creator: selectedGroup.created_by.username || "",
              }}
              handleInfoClick={handleInfoClick}
            />
          )}
        </div>

        {showGroupSetting && selectedGroup && <GroupSetting selectedGroup={selectedGroup} />}
      </div>
    </div>
  );
};

export default GroupsChat;