import React from 'react'
import RankMessage from './RankBadge';

const SideBar = () => {
    return (
        <div className="w-full flex justify-center md:block md:w-[300px] md:absolute md:right-[80px]">
            <div className="w-full max-w-[300px] relative px-4 md:px-0">
                <div className="relative">
                    <div className="flex-col absolute top-[220px] left-0 right-0 overflow-hidden border-solid h-[200px]">
                        <RankMessage
                            rank={74}
                            message="You are doing better than 10% of other learners!"
                        />
                    </div>
                    <div className="flex-col overflow-hidden">
                        <img
                            className="absolute top-[110px] left-1/2 transform -translate-x-1/2 md:left-[113px] md:transform-none w-[85.71px] h-[90.23px] overflow-hidden cursor-pointer"
                            alt=""
                            src="/home/avatar@2x.png"
                        />
                    </div>

                <div className='mq750:top-[920px] mq750:absolute'>

                    <p className=" absolute rounded-[20px] left-[15px] font-[Poppins] text-[12.06px] bg-[#FFFFFF]  h-[305px] w-[228px] line-height-[18.09px] font-[600] 
                text-start text-[#1A3651] top-[340px] items-end pt-[20px] pl-[50px]" >Point Progress</p>

                    <p
                        className=" absolute left-[190px] rounded-[4px] h-[27px] w-[57px] top-[354px] text-center pr-[20px] pt-[8px] bg-[#EFF1F3] text-[#424252] font-[Poppins] font-[600] text-[12.06px]  "> Daily </p>

                    <img
                        className=" absolute left-[125px] top-[263px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
                        alt=""
                        src="/home/Vector 13 (1).svg"
                    />

                    <img
                        className=" absolute left-[35px] top-[355px] w-[254px] h-[240px] rounded-[30px]  overflow object-none cursor-[pointer]"
                        alt=""
                        src="/home/Ellipse 14.svg"
                    />


                    <p className=" absolute w-[100px] rounded-[20px] left-[105px] font-[Poppins] text-[18px]  line-height-[18.09px] font-[500] 
                text-start text-[#83868E] top-[535px] " >Your Point: </p>

                    <p className=" absolute rounded-[20px] left-[215px] font-[Poppins] text-[20px]  line-height-[18.09px] font-[700] 
                text-start text-[black] top-[530px]" >0</p>

                    <img
                        className=" absolute left-[90px] mq750:top-[600px] top-[590px] w-[135px] h-[55px] rounded-[10px]  overflow object-none cursor-[pointer]"
                        alt=""
                        src="/home/Points.svg"
                    />
                    <img
                        className=" absolute left-[30px] top-[395px] w-[230px] h-[230px] rounded-[30px]  overflow object-none cursor-[pointer]"
                        alt=""
                        src="/home/Polygon 4.svg"
                    />

                    <p className=" absolute rounded-[20px] left-[15px] font-[Poppins] text-[16px] bg-[#F9F9F9]  h-[305px] w-[258px] line-height-[22.04px] font-[500] 
                text-start text-[#707070] top-[690px] pt-[20px] pl-[20px]" >Activities and Schedules</p>

                    <img
                        className=" absolute left-[38px] top-[780px] w-[40px] h-[40px] bg-[#FFE4C2] rounded-[8px] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/Rectangle 14 (1).svg"
                    />

                    <img
                        className=" absolute left-[50px] top-[790px] w-[14px] h-[16px] rounded-[30px] bg-[#FFE4C2] borderWidth-[5px] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/Vector.svg"
                    />
                    <p className=" absolute rounded-[20px] left-[65px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[600] 
                text-start text-[#707070] top-[750px] pt-[20px] pl-[20px]" >Meeting with Lorem Ipsum</p>

                    <p className=" absolute rounded-[20px] left-[65px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                text-start text-[#707070] top-[775px] pt-[20px] pl-[20px]" >09:20AM</p>

                    <p className=" absolute rounded-[20px] left-[115px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                text-start text-[#FFAD47] top-[775px] pt-[20px] pl-[20px]" >Due Soon</p>

                    <img
                        className=" absolute left-[260px] top-[790px] w-[10.11px] h-[14x] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/16--chevronright.svg"
                    />

                    <img
                        className=" absolute left-[38px] top-[875px] w-[40px] h-[40px] bg-[#F3E4FF] rounded-[8px] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/Rectangle 14 (2).svg"
                    />

                    <img
                        className=" absolute left-[50px] top-[885px] w-[14px] h-[16px] rounded-[30px] bg-[#F3E4FF] borderWidth-[5px] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/ClipboardList.svg"
                    />
                    <p className=" absolute rounded-[20px] left-[65px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[600] 
                text-start text-[#707070] top-[846px] pt-[20px] pl-[20px]" >Meeting with Lorem Ipsum</p>

                    <p className=" absolute rounded-[20px] left-[65px] font-[Poppins] text-[10px]  h-[305px] w-[258px] line-height-[22.04px] font-[300] 
                text-start text-[#707070] top-[870px] pt-[20px] pl-[20px]" >04 Jan, 09:20AM</p>

                    <img
                        className=" absolute left-[260px] top-[890px] w-[10.11px] h-[14x] overflow-hidden  cursor-[pointer]"
                        alt=""
                        src="/home/16--chevronright.svg"
                    />
                </div>

            </div>



        </div>
        </div>
    )
}

export default SideBar