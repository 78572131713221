import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const PreferencesScreen2 = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/preference-2");
  }, [navigate]);

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-center text-xl text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o3@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_223px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <img
        className="absolute h-[36.06%] w-[23.44%] top-[8.41%] right-[68.75%] bottom-[55.53%] left-[7.81%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-11@2x.png"
      />
      <div className="absolute top-[161px] left-[calc(50%_-_250px)] w-[500px] h-[200px]">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl bg-default-white border-primary-gold border-[4px] border-solid box-border" />
        <div className="absolute top-[26px] left-[24px] leading-[150%] font-medium whitespace-pre-wrap flex items-center justify-center w-[462px]">
          Congratulations and welcome! I am Abu Sensebod, and I am excited to
          have you here. Let's set up your profile and preferences so I may be
          able to give you the best learning experience possible.
        </div>
      </div>
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="470px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="193px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Let’s get you started"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
    </div>
  );
};

export default PreferencesScreen2;
