import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen10 = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const colors = ["#2F80ED", "#27AE60", "#EB5757", "#F2C94C", "#9B51E0"];

  // Function to fetch subjects from the API
  const fetchSubjects = async () => {
    try {
      const token = localStorage.getItem("accessToken"); 

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/focus-subjectarea/",
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );
      console.log(response.data);
      setSubjects(response.data); 
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []); 

  const handleSubjectSelect = (subject) => {
    const isSelected = selectedSubjects.find(
      (item) => item.subject === subject.subject 
    );
    if (isSelected) {
      setSelectedSubjects(
        selectedSubjects.filter((item) => item.subject !== subject.subject)
      );
    } else {
      const color = colors[selectedSubjects.length % colors.length];
      setSelectedSubjects([...selectedSubjects, { subject: subject.subject, color }]);
    }
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected subjects to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      focus_subject_areas: selectedSubjects.map((subject) => subject.subject),
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/10-preferencesscreen");
  }, [navigate, selectedSubjects]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Select the subjects you want to focus on for a customized learning
          path
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="50%"
          rectangleDivRight="50%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          5 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <form className="absolute top-[268px] left-[calc(50%_-_385px)] w-[815.2px] grid grid-cols-3 gap-[16px]">
        {subjects.map((subject, index) => {
          const isSelected = selectedSubjects.find(
            (selected) => selected.subject === subject.subject 
          );
          const backgroundColor = isSelected ? isSelected.color : "#FFFFFF";
          const textColor = isSelected ? "#FFFFFF" : "#0c092a";
          const borderColor = isSelected ? isSelected.color : "#EFEFFC";

          return (
            <div
              key={index}
              onClick={() => handleSubjectSelect(subject)} 
              className="cursor-pointer p-4 rounded-xl border-[2px] border-solid box-border text-left"
              style={{
                backgroundColor: backgroundColor,
                color: textColor,
                borderColor: borderColor,
              }}
            >
              {subject.subject} {/* Rendering the subject name */}
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default PreferencesScreen10;
