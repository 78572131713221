import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Full data set for 12 months
const data = [
  { month: 'Jun 2024', value1: 12, value2: 15 },
  { month: 'Jul 2024', value1: 15, value2: 18 },
  { month: 'Aug 2024', value1: 25, value2: 22 },
  { month: 'Sep 2024', value1: 20, value2: 24 },
  { month: 'Oct 2024', value1: 30, value2: 28 },
  { month: 'Nov 2024', value1: 20, value2: 25 },
  { month: 'Dec 2024', value1: 25, value2: 30 },
  { month: 'Jan 2025', value1: 30, value2: 20 },
  { month: 'Feb 2025', value1: 35, value2: 30 },
  { month: 'Mar 2025', value1: 30, value2: 35 },
  { month: 'Apr 2025', value1: 45, value2: 40 },
  { month: 'May 2025', value1: 35, value2: 45 },
];

const TotalPoints = ({ className = "" }) => {
  // State to hold selected months for filtering data
  const [fromMonth, setFromMonth] = useState("Jun 2024");
  const [toMonth, setToMonth] = useState("May 2025");

  // Get all months from the data array
  const months = data.map(item => item.month);

  // Filter months for the 'to' dropdown based on the 'from' month selection
  const filteredToMonths = months.filter(month => months.indexOf(month) > months.indexOf(fromMonth));

  // Filter data based on the selected from and to months
  const filteredData = data.filter(
    item => months.indexOf(item.month) >= months.indexOf(fromMonth) && months.indexOf(item.month) <= months.indexOf(toMonth)
  );

  return (
    <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] text-left text-xl text-default-white font-poppins ${className}`}>
      <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] shadow-[0px_1px_4px_rgba(0,_0,_0,_0.2)] rounded-xl bg-background-main" />
      
      <div className="absolute w-[91.31%] top-[calc(50%_-_155px)] right-[5.52%] left-[3.17%] h-[30px]">
        <div className="absolute top-[calc(50%_-_15px)] left-[0%]">Points Consistency</div>
        <div className="absolute h-3/5 w-[37.5%] top-[13.33%] right-[0%] bottom-[26.67%] left-[62.5%] text-xs text-[#758aa1]">
          {/* From Month Selector */}
          <div className="absolute h-full w-[48.7%] top-[0%] right-[51.3%] bottom-[0%] left-[0%]">
            <div className="absolute w-[27.22%] top-[calc(50%_-_9px)] left-[0%] inline-block">from</div>
            <div className="absolute top-[calc(50%_-_9px)] left-[35.17%] text-default-white">
              <select
                className="bg-background-main border border-gray-400 rounded px-2 py-1 text-default-white"
                value={fromMonth}
                onChange={(e) => {
                  setFromMonth(e.target.value);
                  setToMonth(filteredToMonths[0]); // Automatically adjust the 'to' month if 'from' changes
                }}
              >
                {months.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>
          </div>

          {/* To Month Selector */}
          <div className="absolute h-full w-[40.55%] top-[0%] right-[0.05%] bottom-[0%] left-[59.41%]">
            <div className="absolute w-[14.61%] top-[calc(50%_-_9px)] left-[0%] inline-block">to</div>
            <div className="absolute top-[calc(50%_-_9px)] left-[20.56%] text-default-white">
              <select
                className="bg-background-main border border-gray-400 rounded px-2 py-1 text-default-white"
                value={toMonth}
                onChange={(e) => setToMonth(e.target.value)}
              >
                {filteredToMonths.map(month => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute h-[67.57%] w-full top-[23.24%] right-[0%] bottom-[9.19%] left-[0.5%] text-center text-3xs">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={filteredData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#33393f" />
            <XAxis dataKey="month" stroke="#758aa1" />
            <YAxis stroke="#758aa1" />
            <Tooltip />
            <Line type="monotone" dataKey="value1" stroke="#f09a19" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="value2" stroke="#fff" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <b className="absolute top-[29px] right-[274px] text-5xl leading-[120%]">Statistics</b>
    </div>
  );
};

TotalPoints.propTypes = {
  className: PropTypes.string,
};

export default TotalPoints;
