import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SigupPage from "./pages/SigupPage";
import PreferencesScreen from "./pages/preference/PreferencesScreen";
import PreferencesScreen1 from "./pages/preference/PreferencesScreen1";
import PreferencesScreen2 from "./pages/preference/PreferenceScreen-1";
import PreferencesScreen3 from "./pages/preference/PreferencesScreen3";
import PreferencesScreen4 from "./pages/preference/PreferencesScreen4";
import PreferencesScreen5 from "./pages/preference/PreferencesScreen5";
import PreferencesScreen6 from "./pages/preference/PreferencesScreen6";
import PreferencesScreen7 from "./pages/preference/PreferencesScreen7";
import PreferencesScreen8 from "./pages/preference/PreferencesScreen8";
import PreferencesScreen9 from "./pages/preference/PreferencesScreen-2";
import PreferencesScreen10 from "./pages/preference/PreferencesScreen10";
import PreferencesScreen11 from "./pages/preference/PreferencesScreen11";
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import LearningHome from "./pages/learning/LearningHome";
import SingleCourse from "./pages/learning/SingleCourse";
import ProtectedRoute from "./components/ProtectedRoute";
import StartCourse from "./pages/learning/StartCourse";
import Quiz from "./pages/learning/Quiz";
import StartQuiz from "./pages/learning/StartQuiz";
import QuizComplete from "./pages/learning/QuizComplete";
import QuizQuestions from "./pages/learning/QuizQuestions";
import Group from "./pages/groups/Group";
import GroupsPreferencesScreen from "./pages/groups/GroupsPreferencesScreen";
import GroupsPreferencesScreen1 from "./pages/groups/GroupsPreferencesScreen1";
import GroupsPreferencesScreen2 from "./pages/groups/GroupsPreferencesScreen2";
import Mentor from "./pages/mentor/Mentor";
import Mentor3 from "./pages/mentor/Mentor3";
import HumanMentors from "./pages/mentor/HumanMentors";
import AIChat from "./pages/mentor/AIChat";
import HumanMentor from "./pages/mentor/HumanMentor";
import Chat from "./pages/mentor/Chat";
import GroupsChat from "./pages/groups/GroupsChat";
import Profile from "./pages/profile/Profile";
import SchoolLeaderboard from "./pages/profile/SchoolLeaderboard";
import NationalLeaderboard from "./pages/profile/NationalLeaderboard";
import SchoolStuLeaderboard from "./pages/profile/SchoolStuLeaderboard";
import Achievements from "./pages/profile/Achievements";
import Settting from "./pages/profile/Settting";
import Statistics from "./pages/profile/Statistics";
import SetttingPassword from "./pages/profile/SetttingPassword";
import CoursesPage from "./pages/learning/CoursesPage";
import MembershipRequests from "./pages/groups/MembershipRequests";
import MyGroups from "./pages/groups/MyGroups";
import JoinGroup from "./pages/groups/JoinGroup";
import GroupChat from "./pages/groups/GroupChat";
import HomePage from "./pages/groups/HomePage";
import RegistrantsComponent from "./components/learning/Registrant/RegistrantsComponent";
import GroupMembersComponent from "./components/groups/GroupMembersComponent";
import SingleGroup from "./pages/groups/SingleGroup";
import SettingPreference from "./pages/profile/SettingPreference";
import UserPreference from "./pages/profile/UserPreference";
import FlashcardApp from "./pages/learning/FlashcardApp";
import MyCourse from "./pages/learning/MyCourse";
import NotFound from "./pages/NotFound";
import AIQuiz from "./pages/learning/AIQuiz";
import AIQuizQuestions from "./pages/learning/AIQuizQuestions";
import AIQuizComplete from "./pages/learning/AIQuizComplete";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/signup":
        title = "Sign Up";
        metaDescription = "";
        break;
      case "/13-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/11-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/3-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/preference-3":
        title = "";
        metaDescription = "";
        break;
      case "/6-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/7-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/14-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/12-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/8-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/4-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/9-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/10-preferencesscreen":
        title = "";
        metaDescription = "";
        break;
      case "/Home":
        title = "Dashboard";
        metaDescription = "";
        break;
      case "/course/learning-home":
        title = "Learning";
        metaDescription = "";
        break;
      case "/single-course/:slug/:id":
        title = "Course Preview";
        metaDescription = "";
        break;
        
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/home2" element={<ProtectedRoute><Home2 /></ProtectedRoute>} />
      <Route path="/home3" element={<ProtectedRoute><Home3 /></ProtectedRoute>} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SigupPage />} />

      {/* Learning Screens */}
      <Route path="/course/learning-home" element={<ProtectedRoute><LearningHome /></ProtectedRoute>} />

      
      {/* <Route path="/single-course/:slug?/:id?" element={<ProtectedRoute><SingleCourse /></ProtectedRoute>} /> */}
      <Route path="/course/:slug/:id" element={<ProtectedRoute><SingleCourse /></ProtectedRoute>} />
      <Route path="/single-course" element={<ProtectedRoute><SingleCourse /></ProtectedRoute>} />
      {/* <Route path="/course" element={<ProtectedRoute><StartCourse /></ProtectedRoute>} /> */}
      <Route path="/courses" element={<ProtectedRoute><CoursesPage /></ProtectedRoute>} />  
      <Route
        path="/course/startcourse/:slug/:id"
        element={<ProtectedRoute><StartCourse /></ProtectedRoute>}
      />
      <Route path="/quiz" element={<ProtectedRoute><Quiz /></ProtectedRoute>} />
      <Route path="/start-quiz" element={<ProtectedRoute><StartQuiz /></ProtectedRoute>} />
      <Route
        path="/start-quiz/:lessonTitle/:itemTitle"
        element={<ProtectedRoute><StartQuiz /></ProtectedRoute>}
      />
      <Route path="/quiz-complete" element={<ProtectedRoute><QuizComplete /></ProtectedRoute>} />
      <Route path="/quiz-questions" element={<ProtectedRoute><QuizQuestions /></ProtectedRoute>} />
      <Route path="/registrants" element={<ProtectedRoute><RegistrantsComponent /></ProtectedRoute>} />
      <Route path="/flashcard" element={<ProtectedRoute><FlashcardApp /></ProtectedRoute>} />
      <Route path="/course/mycourses" element={<ProtectedRoute><MyCourse /></ProtectedRoute>} />
      <Route path="/course/quiz-ai" element={<ProtectedRoute><AIQuiz /></ProtectedRoute>} />
      <Route path="/course/ai-quiz-questions" element={<ProtectedRoute><AIQuizQuestions /></ProtectedRoute>} />
      <Route path="/course/ai-quiz-complete" element={<ProtectedRoute><AIQuizComplete /></ProtectedRoute>} />



      {/* Mentor Screens */}
      <Route path="/mentor" element={<ProtectedRoute><Mentor /></ProtectedRoute>} />
      <Route path="/mentor/HumanMentor" element={<ProtectedRoute>< HumanMentor /></ProtectedRoute>} />
      <Route path="/Mentor3" element={<ProtectedRoute>< Mentor3 /></ProtectedRoute>} />
      <Route path="/mentor/HumanMentors" element={<ProtectedRoute>< HumanMentors /></ProtectedRoute>} />
      <Route path="/aichat" element={<ProtectedRoute><AIChat /></ProtectedRoute>} />
      <Route path="/Chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />

      {/* Group Screens */}
      <Route path="/groups" element={<ProtectedRoute><Group /></ProtectedRoute>} />
      {/* <Route path="/groups-chat" element={<ProtectedRoute><GroupsChat /></ProtectedRoute>} /> */}
      <Route path="/group/groups-chat/:uuid" element={<ProtectedRoute><GroupsChat /></ProtectedRoute>} />
      <Route path="/groups-preferences" element={<ProtectedRoute><GroupsPreferencesScreen /></ProtectedRoute>} />
      <Route path="/groups-preferences-1" element={<ProtectedRoute><GroupsPreferencesScreen1 /></ProtectedRoute>} />
      <Route path="/groups-preferences-2" element={<ProtectedRoute><GroupsPreferencesScreen2 /></ProtectedRoute>} />
      <Route path="/membership-requests/:groupId" element={<ProtectedRoute><MembershipRequests /></ProtectedRoute>} />
      <Route path="/my-groups" element={<ProtectedRoute><MyGroups /></ProtectedRoute>} />
      <Route path="/groups/join/:groupId" element={<ProtectedRoute><JoinGroup /></ProtectedRoute>} />
      <Route path="/group-chat" element={<ProtectedRoute><GroupChat /> </ProtectedRoute>} />
      <Route path="/group-chat/:groupId" element={<ProtectedRoute><GroupChat /> </ProtectedRoute>} />
      <Route path="/chat/:groupId/:chatId" element={<ProtectedRoute><GroupChat /> </ProtectedRoute>} />
      <Route path="/homepage" element={<ProtectedRoute><HomePage/></ProtectedRoute>} />
      <Route path="/group-members" element={<ProtectedRoute><GroupMembersComponent /></ProtectedRoute>} />
      <Route path="/single-group" element={<ProtectedRoute><SingleGroup /></ProtectedRoute>} />

 
      


      {/* Profile Screens */}
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/school-leaderboard" element={<ProtectedRoute><SchoolLeaderboard /></ProtectedRoute>} />
      <Route path="/national-leaderboard" element={<ProtectedRoute><NationalLeaderboard /></ProtectedRoute>} />
      <Route path="/school-student-leaderboard" element={<ProtectedRoute><SchoolStuLeaderboard /></ProtectedRoute>} />
      <Route path="/achievements" element={<ProtectedRoute><Achievements /></ProtectedRoute>} />
      <Route path="/statistics" element={<ProtectedRoute><Statistics /></ProtectedRoute>} />
      <Route path="/settings" element={<ProtectedRoute><Settting /></ProtectedRoute>} />
      <Route path="/password" element={<ProtectedRoute><SetttingPassword /></ProtectedRoute>} />
      <Route path="/setting-preference" element={<ProtectedRoute><SettingPreference /></ProtectedRoute>} />
      <Route path="/user-preference" element={<ProtectedRoute><UserPreference /></ProtectedRoute>} />




      {/* Protected Preferences Screens */}
      <Route path="/preference-1" element={<ProtectedRoute><PreferencesScreen2 /></ProtectedRoute>} />
      <Route path="/preference-2" element={<ProtectedRoute><PreferencesScreen9 /></ProtectedRoute>} />
      <Route path="/preference-3" element={<ProtectedRoute><PreferencesScreen3 /></ProtectedRoute>} />
      <Route path="/13-preferencesscreen" element={<ProtectedRoute><PreferencesScreen /></ProtectedRoute>} />
      <Route path="/11-preferencesscreen" element={<ProtectedRoute><PreferencesScreen1 /></ProtectedRoute>} />
      <Route path="/6-preferencesscreen" element={<ProtectedRoute><PreferencesScreen4 /></ProtectedRoute>} />
      <Route path="/7-preferencesscreen" element={<ProtectedRoute><PreferencesScreen5 /></ProtectedRoute>} />
      <Route path="/14-preferencesscreen" element={<ProtectedRoute><PreferencesScreen6 /></ProtectedRoute>} />
      <Route path="/12-preferencesscreen" element={<ProtectedRoute><PreferencesScreen7 /></ProtectedRoute>} />
      <Route path="/8-preferencesscreen" element={<ProtectedRoute><PreferencesScreen8 /></ProtectedRoute>} />
      <Route path="/9-preferencesscreen" element={<ProtectedRoute><PreferencesScreen10 /></ProtectedRoute>} />
      <Route path="/10-preferencesscreen" element={<ProtectedRoute><PreferencesScreen11 /></ProtectedRoute>} />
    </Routes>
  );
}
export default App;
