import React, { useState } from 'react';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const SummaryCalendar = ({ className = '' }) => {
  const [date, setDate] = useState(new Date());

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const renderCalendar = () => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);

    let days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="w-9 h-9"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      const isToday = currentDate.toDateString() === new Date().toDateString();
      const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

      days.push(
        <div
          key={day}
          className={`w-9 h-9 flex items-center justify-center ${
            isToday
              ? 'bg-orange-500 text-white rounded-full'
              : isWeekend
              ? 'text-gray-600'
              : 'text-gray-700'
          }`}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const prevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  };

  return (
    <div className={`relative w-[325px] top-[71px] bg-white rounded-[8px] p-6 ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={prevMonth}
          className="flex items-center justify-center w-9 h-9 border border-gray-300 text-gray-500 hover:border-yellow-500 hover:text-yellow-500 transition-colors duration-200"
        >
          <ChevronLeft size={20} />
        </button>
        <div className="text-lg font-semibold text-black">
          {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <button
          onClick={nextMonth}
          className="flex items-center cursor-pointer justify-center w-9 h-9 border border-gray-300 text-gray-500 hover:border-primary-gold hover:text-primary-gold transition-colors duration-200"
        >
          <ChevronRight size={20} />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 mb-2">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center font-medium text-gray-500">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-1">{renderCalendar()}</div>
    </div>
  );
};

export default SummaryCalendar;