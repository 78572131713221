import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const PreferencesScreen3 = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve userId and token from localStorage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");

    if (!userId || !token) {
      console.error("User ID or token not found in localStorage.");
      return;
    }

    try {
      // Make the PATCH request to update the user's information
      const response = await axios.patch(
        `https://api.staging.sensebod.com/api/v1/users/${userId}/`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          date_of_birth: selectedDate ? selectedDate.toISOString().split("T")[0] : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("User data updated successfully:", response.data);
        // Navigate to the next screen
        navigate("/6-preferencesscreen");
      } else {
        console.error("Failed to update user data:", response.data);
      }
    } catch (error) {
      console.error("An error occurred while updating user data:", error);
    }
  };

  const handleGoBack = () => {
    navigate(-1); // This will nnavigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/6-preferencesscreen");
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-left text-sm text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <form onSubmit={handleSubmit}>
        <div className="absolute top-[261px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">First Name</div>
          </div>
          <div className="self-stretch rounded-[12px] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm text-[#939699]">
            <div className="flex flex-row items-center justify-start gap-[8px]">
              <img
                className="w-[20px] relative h-[20px]"
                alt=""
                src="/my-profile.svg"
              />
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder=""
                className="border-none w-[300px] text-black leading-tight focus:outline-none font-poppins text-[14px]"
              />
            </div>
          </div>
        </div>

        <div className="absolute top-[349px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">Last Name</div>
          </div>
          <div className="self-stretch rounded-[12px] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm text-[#939699]">
            <div className="flex flex-row items-center justify-start gap-[8px]">
            <img
                className="w-[20px] relative h-[20px]"
                alt=""
                src="/my-profile.svg"
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder=""
                className="border-none w-[300px] text-black leading-tight focus:outline-none font-poppins text-[14px]"
              />
            </div>
          </div>
        </div>

        <div className="absolute top-[437px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">
              Email
            </div>
          </div>
          <div className="self-stretch rounded-[12px] border-[#efeefc] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-sm text-[#939699]">
            <div className="flex flex-row items-center justify-start gap-[8px]">
            <img
                className="w-[20px] relative h-[20px]"
                alt=""
                src="/icon2.svg"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                className="border-none w-[300px] text-black leading-tight focus:outline-none font-poppins text-[14px]"
              />
            </div>
          </div>
        </div>

        <div className="absolute top-[525px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start gap-[4px] text-xs">
          <div className="self-stretch flex flex-row items-center justify-start">
            <div className="relative tracking-[-0.01em] leading-[20px]">
              Date of Birth (optional)
            </div>
          </div>
          <div className="w-[370px] rounded-[12px] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-[#939699]">
            <div className="flex flex-row items-center justify-start gap-[8px]">
              <img
                className="w-[20px] relative h-[20px] overflow-hidden shrink-0"
                alt=""
                src="/calendar.svg"
              />
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                placeholderText="Select date of birth"
                className="outline-none bg-transparent"
                dateFormat="MMMM d, yyyy"
              />
            </div>
            <div className="flex flex-row items-center justify-end gap-[4px]">
              <img
                className="w-[20px] relative h-[20px]"
                alt=""
                src="/chevrondown.svg"
              />
            </div>
          </div>
        </div>

        <Button
          buttonWidth="370px"
          buttonPosition="absolute"
          buttonBackgroundColor="#055db2"
          buttonPadding="16px 133px"
          buttonBottom="137px"
          buttonLeft="calc(50% - 185px)"
          buttonTop="unset"
          button="Next"
          buttonFontSize="16px"
          buttonFontWeight="bold"
          buttonFontFamily="Poppins"
          type="submit"
        />
      </form>

      <div className="absolute top-[110px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Please provide some basic details to help me personalize your learning
          experience.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="10%"
          rectangleDivRight="90%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          1 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[730px] left-[calc(50%_-_15px)] w-[30px] h-[16px] text-right text-[#c3beb5] font-poppins">
        <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium cursor-pointer" onClick={handleSkip}>
          Skip
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen3;
