import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AIQuizComplete = () => {
  const navigate = useNavigate();
  const [quizResults, setQuizResults] = useState({
    quizName: "",
    totalPoints: 0,
    maxPossiblePoints: 0,
    correctAnswers: 0,
    incorrectAnswers: 0,
    skippedQuestions: 0,
    userAnswers: [],
    quizType: "",
  });

  useEffect(() => {
    const storedResults = localStorage.getItem("quizResults");

    if (!storedResults){
      navigate("/course/quiz-ai");
    }

    if (storedResults) {
      setQuizResults(JSON.parse(storedResults));
    }
  }, []);

  const handleRetry = () => {
    localStorage.removeItem("quizResults");
    navigate("/course/quiz-ai");
  };

  const handleContinue = () => {
    localStorage.removeItem("quizResults");
    navigate("/course/learning-home");
  };

  return (
    <div className="w-full min-h-screen bg-background-main text-left text-xs text-default-white font-poppins font-body-xs flex flex-col items-center py-10 px-4 sm:px-6 md:px-8">
      {/* Top Images */}
      <div className="relative w-[80%] flex justify-center items-center flex-wrap gap-4 mb-10">
        <img
          className="w-full max-w-[640px] h-auto object-contain"
          alt=""
          src="/learning/allura-celebrations@2x.png"
        />
      </div>

      {/* Quiz Name */}
      <div className="text-3xl sm:text-3xl font-bold text-primary-gold mb-6 text-center">
        {quizResults.quizName}
      </div>

      {/* Points and Summary */}
      <div className="rounded-xl bg-gradient-to-b from-[#055db2] to-[#14b8b9] w-[80%] max-w-[503px] p-4 sm:p-8 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8 text-sm text-[#a7e0c4] mb-10">
        <div className="w-[70px] h-[70px] relative overflow-hidden shrink-0">
          <div className="absolute top-[28px] left-[30px] tracking-[-0.4px]">
            /{quizResults.userAnswers.length}
          </div>
          <div className="absolute top-[26px] left-[18px] text-xl leading-[16px] font-medium text-default-white">
            {quizResults.correctAnswers}
          </div>
          <div className="absolute top-[0px] left-[0px] rounded-[50%] border-[#5bc691] border-[6px] border-solid box-border w-[70px] h-[70px]" />
        </div>
        <div className="flex-1 flex flex-col items-center justify-center gap-4 text-4xl sm:text-[64px]">
          <div className="self-stretch relative leading-[24px] font-semibold text-center sm:text-left">
            <span>+{quizResults.totalPoints}</span>
            <span className="text-base text-default-white"> / {quizResults.maxPossiblePoints} Points</span>
          </div>
          <div className="self-stretch relative text-sm sm:text-base leading-[16px] font-medium font-poppins text-default-white text-center sm:text-left">
            {quizResults.quizType === "objective" 
              ? `You answered ${quizResults.correctAnswers} out of ${quizResults.userAnswers.length} questions correctly`
              : `You completed ${quizResults.userAnswers.length} theory questions`}
          </div>
        </div>
      </div>

      {/* Quiz Summary */}
      <div className="w-full max-w-[444px] text-[15px] text-[#858494] font-poppins mb-10">
        <div className="grid grid-cols-2 gap-4 sm:gap-8 text-center">
          {quizResults.quizType === "objective" && (
            <>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  CORRECT ANSWERS
                </div>
                <div className="text-2xl sm:text-[32px] leading-[140%] font-medium text-default-white">
                  {quizResults.correctAnswers} questions
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  COMPLETION
                </div>
                <div className="text-2xl sm:text-[32px] leading-[140%] font-medium text-default-white">
                  {Math.round((quizResults.correctAnswers / quizResults.userAnswers.length) * 100)}%
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  SKIPPED
                </div>
                <div className="text-lg sm:text-xl leading-[140%] font-medium font-poppins text-default-white">
                  {quizResults.skippedQuestions}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  INCORRECT ANSWERS
                </div>
                <div className="text-lg sm:text-xl leading-[140%] font-medium font-poppins text-default-white">
                  {quizResults.incorrectAnswers}
                </div>
              </div>
            </>
          )}
          {quizResults.quizType === "theory" && (
            <>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  QUESTIONS ANSWERED
                </div>
                <div className="text-2xl sm:text-[32px] leading-[140%] font-medium text-default-white">
                  {quizResults.userAnswers.length}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="tracking-[0.04em] leading-[150%] uppercase font-medium text-xs sm:text-sm">
                  COMPLETION
                </div>
                <div className="text-2xl sm:text-[32px] leading-[140%] font-medium text-default-white">
                  100%
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* User's Answers */}
      <div className="w-full max-w-[696px] flex flex-col items-center text-center mb-10">
        <b className="text-2xl sm:text-[32px] text-primary-gold">Your answers</b>
        <div className="text-lg sm:text-xl text-default-white">
          Congratulations! You have completed this Quiz!
        </div>
      </div>

      {/* Display each question and user's answer */}
      {quizResults.userAnswers.map((answer, index) => (
        <div
          key={index}
          className="w-full mq850:w-[85%] relative left-[-12px] max-w-[679px] bg-[#0e1d2c] rounded-[12px] p-4 mb-4 shadow-md"
        >
          <div className="flex items-center justify-between mb-2">
            <div className="font-semibold">{`Question ${index + 1}`}</div>
          </div>
          <div className="border-t border-[#4f257b] my-2" />
          <div className="mb-4 text-[rgba(255,255,255,0.8)] text-sm sm:text-base">{answer.question}</div>
          <div className="flex flex-col gap-2 text-[rgba(255,255,255,0.8)]">
            {quizResults.quizType === "objective" ? (
              <>
                {answer.selectedOption && (
                  <div
                    className={`px-4 py-2 rounded-[10px] ${
                      answer.isCorrect ? "bg-[#14b8b9]" : "bg-[#df4559]"
                    }`}
                  >
                    <div className="text-xs sm:text-sm text-default-white">Your answer: {answer.selectedOption}</div>
                  </div>
                )}
                {!answer.isCorrect && (
                  <div className="px-4 py-2 rounded-[10px] bg-[#14b8b9]">
                    <div className="text-xs sm:text-sm text-default-white">Correct answer: {answer.correctAnswer}</div>
                  </div>
                )}
              </>
            ) : (
              <div className="px-4 py-2 rounded-[10px] bg-[#14b8b9]">
                <div className="text-xs sm:text-sm text-default-white">Your answer: {answer.selectedOption}</div>
              </div>
            )}
          </div>
        </div>
      ))}

      {/* Action Buttons */}
      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-10">
        <button 
          className="w-full sm:w-auto bg-primary-gold text-[#361757] py-2 px-4 rounded-[10px] font-semibold shadow-sm text-sm sm:text-base"
          onClick={handleRetry}
        >
          New Quiz
        </button>
        <button 
          className="w-full sm:w-auto bg-buttonselect text-default-white py-2 px-4 rounded-[10px] font-semibold shadow-sm text-sm sm:text-base"
          onClick={handleContinue}
        >
          Continue Learning
        </button>
      </div>
    </div>
  );
};

export default AIQuizComplete;