// TopStuSchool.js
import PropTypes from 'prop-types';
import Tag from './Tag';

const TopStuSchool = ({ className = '', student }) => {
  const renderStudent = (student) => {
    switch (student.position) {
      case 1:
        return (
          <div className="absolute top-[110.2px] left-[calc(50%_-_210.3px)] w-[425px] h-[303.9px]">
            <div className="absolute top-[136.6px] left-[calc(50%_-_44.6px)] w-[85.8px] h-[167.3px]">
              {/* Profile */}
              <div className="absolute top-[0px] left-[calc(50%_-_42.9px)] w-[85.8px] h-[167.3px]">
                <div className="absolute top-[10.2px] left-[calc(50%_-_42.9px)] [background:linear-gradient(180deg,_#055db2,_#14b8b9)] w-[85.8px] h-[157.1px]" />
                <img
                  className="absolute top-[0px] left-[calc(50%_-_42.9px)] w-[85.8px] h-[10.2px]"
                  alt=""
                  src={student.rectangleSrc}
                />
              </div>
              {/* Position */}
              <div className="absolute top-[23.5px] left-[calc(50%_-_13.8px)] leading-[140%] font-semibold flex items-center justify-center w-[28.9px] h-[61.5px]">
                {student.position}
              </div>
            </div>
            <div className="absolute top-[0px] left-[calc(50%_-_212.5px)] w-[425px] h-[288.4px] text-base font-[Rubik]">
              <div className="absolute top-[80.4px] left-[calc(50%_-_212.5px)] w-[425px] h-[208.1px]">
                {/* Student Name */}
                <div className="absolute top-[0px] left-[calc(50%_-_51.7px)] leading-[150%] font-medium inline-block w-[120px] h-[24.7px]">
                  {student.name}
                </div>
                {/* Points */}
                <Tag
                  text={`${student.points} Points`}
                  tagPosition="absolute"
                  tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                  tagTop="22.4px"
                  tagLeft="calc(50% - 46.3px)"
                  textTextAlign="center"
                />
                {/* School */}
                
              </div>
              <img
                className="absolute top-[19.3px] left-[calc(50%_-_25.1px)] w-[43.7px] h-[45px] object-cover"
                alt={`${student.name}'s Avatar`}
                src={student.avatar}
              />
              <img
                className="absolute top-[0px] left-[calc(50%_-_17.3px)] w-[31.2px] h-[32.1px]"
                alt="Medal Icon"
                src="/profile/medal.svg"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="absolute top-[150px] left-[calc(50%_-_232.3px)] w-[94px] h-[258.9px]">
            <div className="absolute top-[117.3px] left-[calc(50%_-_40.6px)] w-[81.1px] h-[141.6px]">
              {/* Profile */}
              <div className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[141.6px]">
                <div className="absolute top-[12.6px] left-[calc(50%_-_40.55px)] bg-buttonselect w-[81.1px] h-[129px]" />
                <img
                  className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[12.6px]"
                  alt=""
                  src={student.rectangleSrc}
                />
              </div>
              {/* Position */}
              <div className="absolute top-[23.9px] left-[calc(50%_-_11.85px)] leading-[140%] font-semibold flex items-center justify-center w-[23px] h-[65.3px]">
                {student.position}
              </div>
              {/* School */}
              
            </div>
            <div className="absolute top-[0px] left-[calc(50%_-_47px)] w-[94px] h-[117.5px] text-base font-[Rubik]">
              <div className="absolute top-[61.1px] left-[calc(50%_-_47px)] w-[94px] h-[56.5px]">
                {/* Student Name */}
                <div className="absolute top-[0px] left-[calc(50%_-_60.6px)] leading-[150%] font-medium inline-block w-[120px] h-[24.7px]">
                  {student.name}
                </div>
                {/* Points */}
                <Tag
                  text={`${student.points} Points`}
                  tagPosition="absolute"
                  tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                  tagTop="22.5px"
                  tagLeft="calc(50% - 47px)"
                  textTextAlign="center"
                />
              </div>
              <img
                className="absolute top-[0px] left-[calc(50%_-_23.4px)] w-[43.7px] h-[45px] object-cover"
                alt={`${student.name}'s Avatar`}
                src={student.avatar}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="absolute top-[170px] left-[calc(50%_+_138.3px)] w-[94px] h-[233.2px]">
            <div className="absolute top-[117.3px] left-[calc(50%_-_39.2px)] w-[81.1px] h-[115.9px]">
              <div className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[115.9px]">
                <div className="absolute top-[10.3px] left-[calc(50%_-_40.55px)] bg-buttonselect w-[81.1px] h-[105.6px]" />
                <img
                  className="absolute top-[0px] left-[calc(50%_-_40.55px)] w-[81.1px] h-[10.3px]"
                  alt=""
                  src={student.rectangleSrc}
                />
              </div>
              {/* Position */}
              <div className="absolute top-[9.8px] left-[calc(50%_-_12.75px)] leading-[140%] font-semibold flex items-center justify-center w-[24px] h-[53px]">
                {student.position}
              </div>
              {/* School */}
             
            </div>
            <div className="absolute top-[0px] left-[calc(50%_-_47px)] w-[94px] h-[117.5px] text-base font-[Rubik]">
              {/* Profile */}
              <img
                className="absolute top-[0px] left-[calc(50%_-_22.8px)] w-[43.7px] h-[45px] object-cover"
                alt={`${student.name}'s Avatar`}
                src={student.avatar}
              />
              <div className="absolute top-[61.1px] left-[calc(50%_-_47px)] w-[94px] h-[56.5px]">
                {/* Points */}
                <Tag
                  text={`${student.points} Points`}
                  tagPosition="absolute"
                  tagBackgroundColor="rgba(20, 184, 185, 0.2)"
                  tagTop="22.5px"
                  tagLeft="calc(50% - 41.7px)"
                  textTextAlign="left"
                />
                {/* Student Name */}
                <div className="absolute top-[0px] left-[calc(50%_-_47px)] leading-[150%] font-medium inline-block w-[94px] h-[24.7px]">
                  {student.name}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null; // Handle other positions if needed
    }
  };

  return <div className={`relative ${className}`}>{renderStudent(student)}</div>;
};

TopStuSchool.propTypes = {
  className: PropTypes.string,
  student: PropTypes.shape({
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    avatar: PropTypes.string.isRequired,
    rectangleSrc: PropTypes.string, // URL for the rectangle background image
    school: PropTypes.string.isRequired,
  }).isRequired,
};

export default TopStuSchool;
