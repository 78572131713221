import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen11 = () => {
  const navigate = useNavigate();
  const [timeOptions, setTimeOptions] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  // Helper function to convert time strings to total minutes
  const convertToMinutes = (timeStr) => {
    const lowerStr = timeStr.toLowerCase();
    let totalMinutes = 0;

    const minuteMatch = lowerStr.match(/(\d+)\s*minute/);
    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1], 10);
    }

    const hourMatch = lowerStr.match(/(\d+)\s*hour/);
    if (hourMatch) {
      totalMinutes += parseInt(hourMatch[1], 10) * 60;
    }

    return totalMinutes;
  };

  // Function to fetch daily targets from the API
  const fetchDailyTargets = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await axios.get(
        "https://api.staging.sensebod.com/api/v1/preference/daily-target/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let fetchedData = response.data;

      // Sort the fetched data from smallest to largest based on duration
      fetchedData.sort((a, b) => {
        const aMinutes = convertToMinutes(a.daily_target);
        const bMinutes = convertToMinutes(b.daily_target);
        return aMinutes - bMinutes;
      });

      setTimeOptions(fetchedData);
      console.log("Time", fetchedData);
    } catch (error) {
      console.error("Error fetching daily targets:", error);
    }
  };

  useEffect(() => {
    fetchDailyTargets();
  }, []);

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected daily target to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      daily_target: selectedTime,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/11-preferencesscreen");
  }, [navigate, selectedTime]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };

  const handleSkip = () => {
    navigate("/11-preferencesscreen");
  }

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-black font-poppins">
      {/* Background Images and Decorations */}
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />

      {/* Next Button */}
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />

      {/* Instruction Section */}
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Set your daily learning target to fit your schedule and goals.
        </div>
      </div>

      {/* Additional Images */}
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />

      {/* Progress Bar and Navigation */}
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="60%"
          rectangleDivRight="40%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          6 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>

      {/* Skip Option */}
      <div className="absolute top-[760px] left-[calc(50%_-_15px)] w-[30px] h-[16px] text-[#c3beb5] font-poppins">
        <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium cursor-pointer" onClick={handleSkip}>
          Skip
        </div>
      </div>

      {/* Daily Target Selection Form */}
      <form className="absolute top-[300px] left-[calc(50%_-_385px)] w-[770px] flex flex-wrap gap-[16px]">
        {timeOptions.map((option, index) => (
          <div
            key={index}
            onClick={() => handleTimeSelect(option.daily_target)}
            className={`cursor-pointer p-4 rounded-xl border-[2px] border-solid box-border text-left w-[370px] ${
              selectedTime === option.daily_target
                ? "bg-green-200 border-[#EFEFFC]"
                : "bg-white border-[#EFEFFC]"
            }`}
          >
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <img className="w-[20px] h-[20px]" alt="" src="/time.svg" />
              <div className="tracking-[-0.03em] leading-[20px] font-semibold">
                {option.daily_target}
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default PreferencesScreen11;
