import { useMemo } from "react";
import PropTypes from "prop-types";

const Input = ({
  className = "",
  inputPosition,
  inputPadding,
  inputGap,
  inputBottom,
  inputLeft,
  inputWidth,
  inputTop,
  icon,
  iconWidth,
  iconHeight,
  placeholder = "Enter text",
  value,
  onChange,
  type = "text",  // Add a type prop with default value "text"
  toggleIcon,     // Add a toggleIcon prop for password visibility toggle
  onToggleIconClick,  // Add a prop for handling the eye icon click
}) => {
  const inputStyle = useMemo(() => {
    return {
      position: inputPosition,
      padding: inputPadding,
      gap: inputGap,
      bottom: inputBottom,
      left: inputLeft,
      top: inputTop,
      width: inputWidth,
    };
  }, [
    inputPosition,
    inputPadding,
    inputGap,
    inputBottom,
    inputLeft,
    inputTop,
    inputWidth,
  ]);

  const iconStyle = useMemo(() => {
    return {
      width: iconWidth,
      height: iconHeight,
    };
  }, [iconWidth, iconHeight]);

  return (
    <div
      className={`relative rounded-xl bg-default-white border-[#efeefc] border-[2px] border-solid flex flex-row items-center justify-start p-[16px] gap-[16px] ${className}`}
      style={inputStyle}
    >
      {icon && (
        <img
          className="w-[24px] h-[24px] overflow-hidden shrink-0"
          alt=""
          src={icon}
          style={iconStyle}
        />
      )}
      <input
        type={type}  // Set the input type based on the prop
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="flex-1 outline-none border-none bg-transparent text-[#999] text-base font-poppins"
      />
      {toggleIcon && (
        <img
          className="w-[24px] h-[24px] cursor-pointer ml-2" // Add cursor pointer for click
          alt="Toggle visibility"
          src={toggleIcon}
          onClick={onToggleIconClick} // Handle icon click to toggle password visibility
        />
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["text", "number", "date", "email", "password"]), // Define the type prop

  /** Style props */
  inputPosition: PropTypes.string,
  inputPadding: PropTypes.string,
  inputGap: PropTypes.string,
  inputBottom: PropTypes.string,
  inputLeft: PropTypes.string,
  inputWidth: PropTypes.string,
  inputTop: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,

  /** New props for password visibility toggle */
  toggleIcon: PropTypes.string,
  onToggleIconClick: PropTypes.func,
};

export default Input;