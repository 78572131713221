import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen1 = () => {
  const navigate = useNavigate();
  const [selectedMentor, setSelectedMentor] = useState(null);

  const handleMentorSelect = (mentor) => {
    setSelectedMentor(mentor);
  };

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected mentor type to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      mentor_type: selectedMentor,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/12-preferencesscreen");
  }, [navigate, selectedMentor]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/12-preferencesscreen");
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-center text-sm text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          Would you prefer guidance from an AI mentor or a human mentor?
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="70%"
          rectangleDivRight="30%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          7 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[760px] left-[calc(50%_-_15px)] w-[30px] h-[16px] text-right text-[#c3beb5] font-poppins">
        <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium cursor-pointer" onClick={handleSkip}>
          Skip
        </div>
      </div>
      <form className="absolute h-[24.04%] w-[28.91%] top-[36.06%] right-[35.55%] bottom-[39.9%] left-[35.55%] text-3xs text-[#858494]">
        <div
          className={`absolute h-[46%] w-full top-[0%] right-[0%] bottom-[54%] left-[0%] cursor-pointer`}
          onClick={() => handleMentorSelect("Human Mentor")}
        >
          <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl border-[#efeefc] border-[2px] border-solid box-border ${
            selectedMentor === "Human Mentor" ? "bg-blue-200" : "bg-white"
          }`} />
          <div className="absolute top-[41px] left-[124.5px] leading-[140%] inline-block w-[220.6px]">
            Connect with an experienced human mentor for personalised guidance
            and support.
          </div>
          <div className="absolute top-[11px] left-[170.9px] text-sm leading-[150%] font-semibold text-[#0c092a] text-left inline-block w-[121.1px]">
            Human Mentor
          </div>
          <img
            className="absolute h-[60.87%] w-[17.14%] top-[19.57%] right-[78.59%] bottom-[19.57%] left-[4.27%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/avatar@2x.png"
          />
        </div>
        <div
          className={`absolute h-[46%] w-full top-[54%] right-[0%] bottom-[0%] left-[0%] cursor-pointer`}
          onClick={() => handleMentorSelect("AI Mentor")}
        >
          <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl border-[#efeefc] border-[2px] border-solid box-border ${
            selectedMentor === "AI Mentor" ? "bg-blue-200" : "bg-white"
          }`}/>
          <div className="absolute top-[42px] left-[124.5px] leading-[140%] inline-block w-[220.6px]">
            Get instant, 24/7 assistance from your AI mentor for all your
            learning needs.
          </div>
          <div className="absolute top-[13px] left-[193.5px] text-sm leading-[150%] font-semibold text-[#0c092a] text-left inline-block w-[79.2px]">
            AI Mentor
          </div>
          <img
            className="absolute h-[60.87%] top-[19.57%] bottom-[19.57%] left-[4.27%] max-h-full w-[63.4px]"
            alt=""
            src="/avatar1.svg"
          />
         

        </div>
         {/* both Mentor */}
         <div
          className={`absolute h-[46%] w-full top-[108%] right-[0%] bottom-[0%] left-[0%] cursor-pointer`}
          onClick={() => handleMentorSelect("Both Mentor")}
        >
          <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl border-[#efeefc] border-[2px] border-solid box-border ${
            selectedMentor === "Both Mentor" ? "bg-blue-200" : "bg-white"
          }`}/>
          
          <div className="absolute top-[45px]  left-[193.5px] text-sm leading-[150%] font-semibold text-[#0c092a] text-left inline-block w-[99.2px]">
            Both Mentor
          </div>
          <img
            className="absolute h-[60.87%] top-[19.57%] bottom-[19.57%] left-[4.27%] max-h-full w-[63.4px]"
            alt=""
            src="/avatar1.svg"
          />
          <img
            className="absolute h-[60.87%] w-[17.14%] top-[19.57%] right-[78.59%] bottom-[19.57%] left-[24.27%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/avatar@2x.png"
          />
          </div>
      </form>
    </div>
  );
};

export default PreferencesScreen1;
