import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const PreferencesScreen9 = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/preference-3");
  }, [navigate]);

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-center text-xs text-default-white font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="137px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[350px] w-[185px] h-[131px] text-sm text-black">
        <img
          className="absolute top-[1px] left-[0px] rounded-[3px] w-[185px] h-[130px]"
          alt=""
          src="/vector-11.svg"
        />
        <div className="absolute top-[0px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px]">
          Are you here to learn and grow, or to guide and mentor others? Please
          Choose your role to get started
        </div>
      </div>
      <div className="absolute h-[13.22%] w-[31.25%] top-[37.74%] right-[34.38%] bottom-[49.04%] left-[34.38%]">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl bg-buttonselect border-[#efeefc] border-[2px] border-solid box-border" />
            <div className="absolute top-[32px] left-[120px] leading-[140%] inline-block w-[318px]">
              Embark on your learning journey! As a student, you'll have access
              to a variety of courses, join study groups, participate in
              discussions, and track your progress.
            </div>
            <div className="absolute top-[7px] left-[220px] text-base leading-[150%] font-semibold text-primary-gold text-left">
              Student
            </div>
          </div>
          <img
            className="absolute h-[58.73%] w-[17.5%] top-[21.82%] right-[81%] bottom-[19.45%] left-[1.5%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/hands-graduate@2x.png"
          />
        </div>
      </div>
      <div className="absolute h-[13.22%] w-[31.25%] top-[53.13%] right-[34.38%] bottom-[33.65%] left-[34.38%]">
        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
          <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]">
            <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-xl bg-background-main border-[#efeefc] border-[2px] border-solid box-border" />
            <div className="absolute top-[31px] left-[calc(50%_-_120px)] leading-[140%] inline-block w-[304px]">
              Become a guide and inspire others! As a mentor, you'll have the
              opportunity to lead study groups, create engaging content, and
              provide guidance to students.
            </div>
            <div className="absolute top-[9px] left-[220px] text-base leading-[150%] font-semibold text-primary-gold text-left">
              Tutor
            </div>
          </div>
          <img
            className="absolute h-[58.73%] w-[17.5%] top-[21.82%] right-[81%] bottom-[19.45%] left-[1.5%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/open-peeps-avatar@2x.png"
          />
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[10.46%] right-[80.08%] bottom-[71.51%] left-[8.2%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
    </div>
  );
};

export default PreferencesScreen9;
