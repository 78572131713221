// StudentList.js
import PropTypes from 'prop-types';

const StudentList = ({ className = '', students }) => {
  // Filter students to include only those with positions 4 and above
  const filteredStudents = students.filter(student => student.position >= 4);

  return (
    <div className={`absolute top-[401px] left-[7px] w-[596px] h-[512px] text-left text-xs font-poppins ${className}`}>
      {filteredStudents.map((student, index) => (
        <div
          key={student.position}
          className="absolute top-[48px] left-[0px] w-[596px] h-[48px]"
          style={{ top: `${index * 58}px` }} // Adjusts position dynamically
        >
          <div className="absolute top-[0px] left-[0px] rounded-[12px] bg-[#252728] w-[596px] h-[48px]" />
          {/* Position */}
          <b className="absolute top-[13px] left-[27.8px] text-[14.4px] leading-[150%] inline-block text-center w-[17.4px] [text-shadow:0px_0px_13px_rgba(0,_0,_0,_0.25)]">
            {student.position}
          </b>
          <div className="absolute top-[9px] left-[66px] w-[217.2px] flex flex-row items-center justify-start gap-[12px]">
            {/* Profile */}
            <img
              className="w-[32px] relative h-[32px] object-cover"
              alt={`${student.name}'s Avatar`}
              src={student.avatar}
            />
            {/* Student Name */}
            <b className="relative leading-[150%]">{student.name}</b>
          </div>
          {/* Points */}
          <div className="absolute top-[16px] left-[524px] leading-[150%] text-right">
            {student.points} pts
          </div>
          {/* School */}
          <div className="absolute top-[16px] left-[358px] text-[13px] leading-[150%] font-medium text-primary-gold">
            {student.school}
          </div>
        </div>
      ))}
    </div>
  );
};

StudentList.propTypes = {
  className: PropTypes.string,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      avatar: PropTypes.string.isRequired,
      school: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StudentList;
