import React, { useState, useCallback, useEffect } from "react";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PreferencesScreen6 = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  

  const onButtonContainerClick = useCallback(async () => {
    try {
      // Retrieve data from localStorage
      const preferencesData = JSON.parse(localStorage.getItem("preferencesData"));
      const schoolData = JSON.parse(localStorage.getItem("selectedSchoolData"));
      const userId = localStorage.getItem("userId");
  
      // Initialize an empty patchData object
      const patchData = {};
  
      // Conditionally add school data if available
      if (schoolData) {
        if (schoolData.school) patchData.school = schoolData.school;
        if (schoolData.region) patchData.region = schoolData.region;
        if (schoolData.district) patchData.district = schoolData.district;
      }
  
      // Add preferencesData if available
      if (preferencesData) {
        patchData.preferences = preferencesData;
      }
  
      // Make sure there is some data to patch
      if (Object.keys(patchData).length === 0) {
        console.log("No data available to patch.");
        return;
      }
  
      // Make the PATCH request to update the user's data
      const response = await axios.patch(
        `https://api.staging.sensebod.com/api/v1/users/${userId}/`,
        patchData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
  
      console.log("Patch data sent:", patchData);
  
      if (response.status === 200) {
        console.log("User data updated successfully.");
  
        // Clear all local storage except for accessToken, userId, and refreshToken
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const username = localStorage.getItem("username");
  
        // Clear all local storage
        localStorage.clear();
  
        // Restore the essential items
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("username", username);
  
        // Navigate to the home page or wherever you'd like to direct the user next
        navigate("/home");
      } else {
        console.error("Failed to update user data:", response.data);
      }
    } catch (error) {
      console.error("An error occurred while updating user data:", error);
    }
  }, [navigate]);
  

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />

      <img
        className="absolute top-[183px] left-[calc(50%_-_420px)] w-[300px] h-[300px] object-cover"
        alt=""
        src="/animation--1719088281184-1@2x.png"
      />
      <img
        className="absolute top-[189px] left-[calc(50%_+_308px)] w-[300px] h-[300px] object-cover"
        alt=""
        src="/animation--1719088281184-1@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Let’s dive in"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="100%"
          rectangleDivRight="0%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          10 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      
      <img
        className="absolute top-[198px] left-[calc(50%_-_320px)] w-[600px] h-[422.4px] object-cover"
        alt=""
        src="/illustration@2x.png"
      />
      <div className="absolute top-[132px] left-[132px] w-[542px] h-[82px]" />
      <div className="absolute top-[107px] left-[calc(50%_-_300px)] w-[601px] flex flex-col items-center justify-center gap-[8px] text-center text-5xl text-[#3b3a36] font-poppins">
        <div className="w-[341px] relative h-[32px]">
          <div className="absolute w-full top-[0px] left-[0%] tracking-[-0.5px] leading-[32px] font-semibold inline-block">
            Congratulations
          </div>
        </div>
        <div className="w-[341px] relative text-sm leading-[21px] text-[#78746d] inline-block">{`You are all set for a wonderful learning experience. `}</div>
      </div>
      <img
        className="absolute top-[72px] left-[387.5px] w-[300px] h-[300px] object-cover"
        alt=""
        src="/animation--1719088281184-2@2x.png"
      />
    </div>
  );
};

export default PreferencesScreen6;
