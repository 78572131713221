import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import axios from "axios";

const PreferencesScreen4 = () => {
  const navigate = useNavigate();

  const onButtonContainerClick = useCallback(() => {
    navigate("/7-preferencesscreen");
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  // Skip
  const handleSkip = () => {
    navigate("/7-preferencesscreen");
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(""); // Holds the country short code
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://api.staging.sensebod.com/api/v1/countries/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // Fetch regions based on selected country's short code
  useEffect(() => {
    const fetchRegions = async () => {
      if (!selectedCountryCode) return; // Do not fetch if no country code is selected

      try {
        const response = await axios.get(
          `https://api.staging.sensebod.com/api/v1/regions/?country_short_code=${selectedCountryCode}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setRegions(response.data);
      } catch (error) {
        console.error("Error fetching regions:", error);
      }
    };

    fetchRegions();
  }, [selectedCountryCode]);

  // Fetch districts based on selected region id
  useEffect(() => {
    const fetchDistricts = async () => {
      if (!region) return; // Do not fetch if no region is selected
      try {
        const response = await axios.get(
          `https://api.staging.sensebod.com/api/v1/districts/?region_id=${region}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setDistricts(response.data);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, [region]);

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find((country) => country.country === e.target.value);
    if (selectedCountry) {
      setSelectedCountry(selectedCountry.country);
      setSelectedCountryCode(selectedCountry.country_short_code);

      // Store country details in localStorage
      localStorage.setItem("country", selectedCountry.country);
      localStorage.setItem("country_short_code", selectedCountry.country_short_code);

      // Reset regions and districts when country changes
      setRegions([]);
      setDistricts([]);
      setRegion("");
      setDistrict("");
      localStorage.removeItem("region_name");
      localStorage.removeItem("region_id");
      localStorage.removeItem("district_name");
    }
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    const selectedRegion = regions.find(region => region.region_id === Number(e.target.value));
    if (selectedRegion) {
      // Store region details in localStorage
      localStorage.setItem("region_name", selectedRegion.region_name);
      localStorage.setItem("region_id", selectedRegion.region_id);
    }
    setDistrict(""); // Reset selected district
    localStorage.removeItem("district_name"); // Reset district name in localStorage
  };

  const handleDistrictChange = (e) => {
    const selectedDistrictName = e.target.value;
    setDistrict(selectedDistrictName);

    // Store district name in localStorage
    localStorage.setItem("district_name", selectedDistrictName);
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-[#686b6d] font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />

      <form>
        {/* Country Field */}
        <div className="absolute top-[261px] left-[calc(50%_-_185px)] w-[370px] flex flex-col items-start justify-start text-left text-xs">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px] pt-[50px] pb-[40px]">
            <div className="self-stretch flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.01em] leading-[20px]">Country</div>
            </div>
            <div className="w-[370px] rounded-[16px] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between p-[16px]">
              <div className="flex flex-row items-center justify-start gap-[12px]">
                <select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  className="border-none bg-transparent focus:outline-none w-[300px]"
                >
                  <option value="">Select country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Region Field */}
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.01em] leading-[20px]">Region</div>
            </div>
            <div className="w-[370px] rounded-[16px] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between p-[16px]">
              <div className="flex flex-row items-center justify-start gap-[12px]">
                <select
                  value={region}
                  onChange={handleRegionChange}
                  className="border-none bg-transparent focus:outline-none w-[300px]"
                >
                  <option value="">Select region</option>
                  {regions.map((regionItem, index) => (
                    <option key={index} value={regionItem.region_id}>
                      {regionItem.region_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* District Field */}
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.01em] leading-[20px]">District</div>
            </div>
            <div className="w-[370px] rounded-[16px] border-[#ebebeb] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between p-[16px]">
              <div className="flex flex-row items-center justify-start gap-[12px]">
                <select
                  value={district}
                  onChange={handleDistrictChange}
                  className="border-none bg-transparent focus:outline-none w-[300px]"
                >
                  <option value="">Select district</option>
                  {districts.map((districtItem, index) => (
                    <option key={index} value={districtItem.district_name}>
                      {districtItem.district_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <Button
          onButtonContainerClick={onButtonContainerClick}
          buttonWidth="370px"
          buttonPosition="absolute"
          buttonBackgroundColor="#055db2"
          buttonPadding="16px 133px"
          buttonBottom="137px"
          buttonLeft="calc(50% - 185px)"
          buttonTop="unset"
          button="Next"
          buttonFontSize="16px"
          buttonFontWeight="bold"
          buttonFontFamily="Poppins"
          type="submit"
        />
      </form>

      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center text-black">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          I need your contact information to keep you updated and connected.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="20%"
          rectangleDivRight="90%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          2 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[730px] left-[calc(50%_-_15px)] w-[30px] h-[16px] text-[#c3beb5] font-poppins">
        <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium cursor-pointer" onClick={handleSkip}>
          Skip
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen4;
