import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Leaderboard from "../../components/profile/Leaderboard";
import Rightside from "../../components/profile/Rightside";

const NationalLeaderboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in by checking for the accessToken in localStorage
        const accessToken = localStorage.getItem("accessToken");

        // If no accessToken is found, redirect to the login page
        if (!accessToken) {
            navigate("/login"); // Replace with the actual path to your login page
        }
    }, [navigate]);

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">
            
            <NavBar />
            <MainNavigation
                homeClass=""
                learningClass=""
                mentorClass=""
                groupsClass=""
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>

            <div className="absolute top-[193px] left-[284px] w-[615px] h-[990px] pb-[100px]">
                <Leaderboard />
            </div>
            <Rightside />


            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      
        </div>
    );
};

export default NationalLeaderboard;
