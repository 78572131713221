import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";

const Mentor = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen md:h-[1170px] overflow-x-hidden text-center text-[6px] text-default-white font-poppins">
      {/* Hidden images remain unchanged */}

      <a href="/aichat" className="fixed bottom-6 left-4 md:left-[285px] z-[10000]">
        <img
          className="w-[60px] h-[60px] md:w-[75px] md:h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />

      <div className="flex flex-col items-center px-4 md:px-0 mt-20 md:mt-0 mq750:absolute mq750:right-[20px] mq750:left-[20px]">
        <p className="text-[#0D0C0C] text-2xl mq750:text-[24px] font-semibold mb-6 md:absolute md:top-[80px] md:left-[620px] ">Mentors</p>

        <div className="w-full md:w-[649px] bg-white rounded-[20px] p-6 md:p-0 md:absolute md:left-[285px] md:top-[175px] md:h-[330px]">
          <p className="text-[#1D2023] text-base md:text-[16px] font-normal mb-6 md:absolute md:top-[30px] md:left-[35px] md:w-[580px]">
            Hello {username}, ready for a mentoring session? Your default mentor is Human. You can always switch to an AI chat when needed
          </p>

          <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:space-x-6 md:absolute md:top-[170px] md:left-[30px]">
          <div className="bg-[#095353] rounded-[32px] p-6 w-full mq750:w-[80%] md:w-[263px] md:h-[138px] text-white">
              <p className="text-lg md:text-[20px] font-semibold mb-4">Select a human mentor to converse with.</p>
              <div className="flex items-center justify-between">
                <div className="bg-gray-400 rounded-full w-10 h-10 flex items-center justify-center">
                <img src="/mentor/conversation 1.svg" alt="" className="w-6 h-6" />
                </div>
                <a href="/mentor/HumanMentors">
                  <img src="/mentor/icon _arrow forward_.svg" alt="" className="w-8 h-8" />
                </a>
              </div>
            </div>
            

            <div className="bg-[#055DB2] rounded-[32px] p-6 w-full mq750:w-[80%] md:w-[263px] md:h-[138px] text-white">
              <p className="text-lg md:text-[20px] font-semibold mb-4">Select an AI mentor to converse with.</p>
              <div className="flex items-center justify-between">
                <div className="bg-gray-400 rounded-full w-10 h-10 flex items-center justify-center">
                  <img src="/mentor/rumor 2.svg" alt="" className="w-6 h-6" />
                </div>
                <a href="/aichat">
                  <img src="/mentor/icon _arrow forward_.svg" alt="" className="w-8 h-8 cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-[258px] bg-[#F9F9F9] rounded-[20px] p-6 mt-6 md:mt-0 md:absolute md:left-[1155px] md:top-[175px] md:h-[390px]">
          <p className="text-[#707070] text-lg font-medium mb-4">Activities and Schedules</p>
          <div className="flex items-start space-x-4">
            <div className="bg-[#FFE4C2] rounded-lg w-10 h-10 flex items-center justify-center">
              <img src="/home/Vector.svg" alt="" className="w-4 h-4" />
            </div>
            <div>
              <p className="text-[#272835] text-sm font-semibold">Meeting with Daphne Cole</p>
              <div className="flex items-center space-x-2">
                <p className="text-[#9E9E9E] text-xs">09:20AM</p>
                <p className="text-[#FFAD47] text-xs">Due Soon</p>
              </div>
            </div>
            <img src="/home/16--chevronright.svg" alt="" className="w-4 h-4 cursor-pointer" />
          </div>
        </div>
      </div>

      <MainNavigation
        homeClass=""
        learningClass=""
        mentorClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        groupsClass="another-custom-class"
        profileClass="custom-profile-class"
      />
    </div>
  );
};

export default Mentor;