import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EyeIcon } from '@heroicons/react/solid';

const GroupRequestsSummary = () => { 
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [membershipRequests, setMembershipRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch Groups Created by User
    const fetchGroupsCreatedByUser = async () => {
        try {
            const response = await fetch(
                "https://api.staging.sensebod.com/api/v1/groups/group/",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                const userId = localStorage.getItem("userId");

                // Filter groups that are created by the current user
                const userGroups = data.filter((group) => group.created_by.user_id === userId);
                console.log("User",data)
                setGroups(userGroups);
            } else {
                console.error("Failed to fetch groups:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching groups:", error);
        }
    };

    // Fetch Membership Requests
    const fetchMembershipRequests = async () => {
        try {
            const response = await fetch(
                "https://api.staging.sensebod.com/api/v1/groups/membership_request/",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                // Filter requests for the groups created by the user
                const requestsForUserGroups = data.filter((request) =>
                    groups.some((group) => group.uuid === request.group.uuid && 
                    request.status === "pending" )
                );
                console.log("RequestedUser",requestsForUserGroups);
                setMembershipRequests(requestsForUserGroups);
                setLoading(false);
            } else {
                console.error("Failed to fetch membership requests:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching membership requests:", error);
        }
    };

    useEffect(() => {
        fetchGroupsCreatedByUser();
    }, []);

    useEffect(() => {
        if (groups.length > 0) {
            fetchMembershipRequests();
        }
    }, [groups]);

    // Group requests by group and count them
    const requestCounts = membershipRequests.reduce((acc, request) => {
        acc[request.group.uuid] = (acc[request.group.uuid] || 0) + 1;
        return acc;
    }, {});

    const handleViewRequests = (groupId) => {
        navigate(`/membership-requests/${groupId}`);
    };

    return (
        <div className="
            w-[616px] h-[232px] 
            mq1024:w-full mq1024:h-auto
            mq850:w-[90%] mq750:mx-auto
            bg-background-main rounded-lg shadow-md p-4 overflow-y-auto
        ">
            <h2 className="text-xl font-semibold text-white mb-4">Group Membership Requests</h2>
            <div className="
                grid grid-cols-4 gap-4
                mq1024:grid-cols-3
                mq750:grid-cols-2
                mq480:grid-cols-1
            ">
                {groups.map((group) => (
                    <div key={group.uuid} className="bg-white p-3 rounded-md">
                        <p className="text-sm font-medium text-gray-700 truncate">{group.name}</p>
                        <div className="mt-2 flex items-center justify-between">
                            <span className="text-xs text-gray-500">Requests:</span>
                            <span className="bg-primary-gold text-white px-2 py-1 rounded-full text-xs font-medium">
                                {requestCounts[group.uuid] || 0}
                            </span>
                        </div>
                        <button 
                            onClick={() => handleViewRequests(group.uuid)}
                            className="mt-2 w-full flex items-center justify-center bg-buttonselect text-white px-2 py-1 rounded-md text-xs cursor-pointer"
                        >
                            <EyeIcon className="h-4 w-4 mr-1" />
                            View Requests
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GroupRequestsSummary;