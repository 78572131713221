import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import RatingAndReviews from "../../components/learning/RatingAndReviews";
import ContentCourse from "../../components/learning/ContentCourse";
import WhatYouLearn from "../../components/learning/WhatYouLearn";
import Accordion1 from "../../components/learning/Accordion1";
import Calender from "../../components/learning/Calender";
import CourseProgress from "../../components/learning/CourseProgress";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Loader from '../../components/Loader';
import Button from "../../components/Button";

const SingleCourse = () => {
  const { slug, id } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [courseProgress, setCourseProgress] = useState(null);

  useEffect(() => {
    const fetchCourseAndRegistrant = async () => {
      if (!slug || !id) {
        setError('Slug or ID is missing');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const accessToken = localStorage.getItem("accessToken");
        const userId = localStorage.getItem("userId");
        if (!accessToken || !userId) {
          throw new Error('Access token or user ID is missing');
        }

        // Fetch course data
        const courseResponse = await fetch(`https://api.staging.sensebod.com/api/v1/courses/course/?id=${id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!courseResponse.ok) {
          throw new Error(`HTTP error! status: ${courseResponse.status}`);
        }

        const courseData = await courseResponse.json();
        setCourse(courseData[0]);

        // Fetch registrant data
        const registrantResponse = await fetch(`https://api.staging.sensebod.com/api/v1/courses/registrant/?user=${userId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!registrantResponse.ok) {
          throw new Error(`HTTP error! status: ${registrantResponse.status}`);
        }

        const registrantData = await registrantResponse.json();

        const currentCourseRegistration = registrantData.find(registration => registration.course.id === parseInt(id));
        if (currentCourseRegistration) {
          setIsEnrolled(true);
          setCourseProgress({
            progress: currentCourseRegistration.completion,
            points: currentCourseRegistration.total_points
          });
        } else {
          setIsEnrolled(false);
          setCourseProgress(null);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourseAndRegistrant();
  }, [slug, id]);


  const handleEnrollClick = () => {
    console.log("Enroll button clicked in SingleCourse");
    setIsDialogOpen(true);
  };

  const confirmEnrollment = async () => {
    setIsEnrolling(true);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      if (!accessToken || !userId) {
        throw new Error('Access token or user ID is missing');
      }

      const response = await fetch('https://api.staging.sensebod.com/api/v1/courses/registrant/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: userId,
          course: id
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Enrollment successful:", data);
      setIsEnrolled(true);
      // Optionally, you can navigate to a course content page or show a success message
      // navigate(`/course/${slug}/content`);
    } catch (error) {
      console.error('Error enrolling in course:', error);
      setError(error.message);
    } finally {
      setIsEnrolling(false);
      setIsDialogOpen(false);
    }
  };

  if (isLoading) {
    return <div className="w-full h-screen flex items-center justify-center"><Loader duration={10000} /></div>;
  }

  if (error) {
    return <div className="w-full h-screen flex items-center justify-center">Error: {error}</div>;
  }

  if (!course) {
    return <div className="w-full h-screen flex items-center justify-center">Course not found. Please check the URL.</div>;
  }

  const baseImageURL = "https://api.staging.sensebod.com";

  return (
    <div className="w-full relative bg-[#fbf1f1] min-h-screen pb-20 overflow-x-hidden text-left text-base text-darkslategray-100 font-poppins">
      <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
      <MainNavigation
        homeClass=""
        learningClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
        mentorClass="custom-mentor-class"
        groupsClass="another-custom-class"
        profileClass="custom-profile-class"
      />

      <div className="absolute">
        <div className="absolute top-[8.875rem] left-[17.813rem] w-[23.688rem] h-[1.188rem] text-[0.875rem] text-light-text-color-body-1">
          {/* Breadcrumb navigation */}
          
        </div>

        <div className="absolute top-[147.125rem] left-[17.813rem] w-[38.913rem] h-[32.688rem]">
          <RatingAndReviews />
        </div>
        <div className="absolute top-[90.188rem] left-[17.688rem] w-[38.75rem] h-[54.938rem]">
          <ContentCourse />
        </div>
        <div className="absolute top-[47.5rem] left-[17.688rem] w-[38.894rem] h-[41.688rem]">
          <WhatYouLearn
            description={course.description}
          />
        </div>

        <div className="absolute top-[13.188rem] left-[17.75rem] w-[38.688rem] h-[32rem]">
          <div className="relative w-[38.688rem] h-[32rem] text-left text-[0.875rem] text-light-text-color-body-2 font-captions-captions-bold">
            <div className="absolute top-0 left-0 rounded-lg bg-default-white w-full h-full" />
            <img
              className="absolute top-[1rem] left-[0.844rem] w-[37.006rem] h-[15rem] object-cover"
              alt={course.name}
              src={course.banner ? `${baseImageURL}${course.banner}` : "/learning/courses/courses.jpg"}
            />
            <b className="absolute top-[16.938rem] left-[1.263rem] text-[2.438rem] tracking-[0.01em] leading-[120%] font-poppins text-gray-500">
              {course.name}
            </b>
            <div className="absolute top-[23.813rem] left-[1.263rem] w-[16.456rem] h-[2rem]">
              <img
                className="absolute top-0 left-0 w-[1.681rem] h-[2rem]"
                alt="Rating icon"
                src="/learning/icon10.svg"
              />
              <div className="absolute top-[0.438rem] left-[2.1rem] tracking-[0.01em] leading-[130%]">
                {course.rating} ({course.reviews_count} Reviews)
              </div>
              <div className="absolute top-[0.438rem] left-[10.519rem] tracking-[0.01em] leading-[130%]">
                {course.registrant_count} students
              </div>
            </div>
            <div className="absolute top-[26.813rem] left-[1.631rem] tracking-[0.01em] leading-[130%]">
              <span>{`Created by `}</span>
              <span className="text-gray-500">{course.instructor.username}</span>
            </div>
            <div className="absolute top-[28.938rem] left-[1.631rem] w-[10.306rem] h-[2rem]">
              <img
                className="absolute top-0 left-0 w-[1.681rem] h-[2rem] object-cover"
                alt="Last update icon"
                src="/learning/icon-search2@2x.png"
              />
              <div className="absolute top-[0.438rem] left-[1.681rem] tracking-[0.01em] leading-[130%]">
                Last update {course.last_updated}
              </div>
            </div>
            <div className="absolute top-[28.938rem] left-[13.563rem] w-[4.681rem] h-[2rem]">
              <img
                className="absolute top-0 left-0 w-[1.681rem] h-[2rem] object-cover"
                alt="Language icon"
                src="/learning/icon-search3@2x.png"
              />
              <div className="absolute top-[0.438rem] left-[1.681rem] tracking-[0.01em] leading-[130%]">
                English
              </div>
            </div>

            <img
              className="absolute top-[17rem] left-[31.956rem] w-[2.525rem] h-[3rem] object-contain"
              alt="Additional icon"
              src="/learning/icon-search5@2x.png"
            />
            <img
              className="absolute top-[17rem] left-[35.325rem] w-[2.525rem] h-[3rem] object-contain"
              alt="Additional icon"
              src="/learning/icon-search6@2x.png"
            />
            <div className="absolute top-[21.313rem] left-[22.75rem] rounded-xl bg-darkslateblue-100 w-[9.313rem] h-[2.938rem] text-center text-[1rem] text-light-text-link-color-white font-poppins">
              <button
                className={`absolute w-full h-full rounded-xl text-[0.75rem] font-poppins cursor-pointer ${isEnrolling ? 'bg-[#cccccc] cursor-not-allowed' : 'bg-[#055db2] hover:bg-[#044e94]'
                  } text-white transition-colors duration-200`}
                onClick={() => {
                  console.log("Button clicked in SingleCourse");
                  if (isEnrolled) {
                    navigate(`/startcourse/${course.slug}/${course.id}`);
                  } else if (!isEnrolling) {
                    handleEnrollClick();
                  }
                }}
                disabled={isEnrolling}
              >
                {isEnrolled ? "Continue Course" : isEnrolling ? "Enrolling..." : "Enroll Now"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-[13.125rem] left-[58.563rem] w-[19.438rem] h-[56.75rem]">
        <div className="absolute top-[0rem] left-[0rem] w-[19.438rem] h-[56.75rem]">
          <div className="absolute top-[25.875rem] left-[0rem] w-[19.438rem] h-[30.875rem]">
            <div className="absolute top-[3.875rem] left-[0rem] flex flex-col items-start justify-start rounded-2xl bg-default-white">
              <Accordion1
                whatYoullLearn="Subjects"
                details="Overview of the subjects covered in this course."
                isLastItem={false}
              />
              <Accordion1
                whatYoullLearn="What you'll learn"
                details="Detailed information about what you'll learn in this course."
                isLastItem={false}
              />
              <Accordion1
                whatYoullLearn="Requirements"
                details="List of requirements needed for this course."
                isLastItem={false}
              />
              <Accordion1
                whatYoullLearn="Description"
                details={course.description}
                isLastItem={false}
              />
              <Accordion1
                whatYoullLearn="Lessons"
                details="Breakdown of the lessons included in the course."
                isLastItem={false}
              />
              <Accordion1
                whatYoullLearn="Student Reviews"
                details="Feedback and reviews from students who have taken this course."
                isLastItem={true}
              />
            </div>
            <b className="absolute top-[0rem] left-[0rem] text-[1.5rem] tracking-[0.01em] leading-[120%] text-gray-500">
              Course Details
            </b>
          </div>
          <Calender />
          {isEnrolled && courseProgress && (
            <CourseProgress 
              className="custom-class" 
              progress={courseProgress.progress || 0} 
              points={courseProgress.points || 0} 
            />
          )}

          <Dialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
              <Dialog.Panel className="w-full max-w-sm rounded bg-white p-6">
                <Dialog.Title className="text-xl font-medium mb-4">Confirm Enrollment</Dialog.Title>
                <Dialog.Description className="mb-4">
                  Are you sure you want to enroll in this course?
                </Dialog.Description>

                <div className="flex justify-end">
                  <button
                    className="px-4 py-2 bg-gray-600 rounded mr-2"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={confirmEnrollment}
                    disabled={isEnrolling}
                  >
                    {isEnrolling ? 'Enrolling...' : 'Confirm'}
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default SingleCourse;