import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import EmojiPicker from "emoji-picker-react";


// Utility function for image resizing
const MAX_IMAGE_SIZE = 300;

const resizeImage = (file, maxSize) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(resolve, file.type);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};

// ImageModal Component
const ImageModal = ({ src, alt, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="max-w-[90%] max-h-[90%] relative">
      <img src={src} alt={alt} className="max-w-full max-h-full" />
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center"
      >
        ×
      </button>
    </div>
  </div>
);

const RightSide = ({ className = "", selectedGroup, handleInfoClick }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [fullSizeImage, setFullSizeImage] = useState(null);
  const [forumId, setForumId] = useState(null);
  const [connected, setConnected] = useState(false);
  const fileInputRef = useRef(null);
  const wsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const typingTimeoutRef = useRef({});

  const token = localStorage.getItem("accessToken");
  const currentUsername = localStorage.getItem("username");

  

  useEffect(() => {
    if (!currentUsername) {
      console.error("Username not found in localStorage");
      // Handle this case, perhaps by redirecting to login
    }
  }, []);

  const connectWebSocket = useCallback(() => {
    if (!token) {
      console.error('No access token found in localStorage');
      return;
    }

    const chatId = forumId || selectedGroup.uuid;
    const socket = new WebSocket(`wss://api.staging.sensebod.com/ws/group-chat/${chatId}/?token=${token}`);

    socket.onopen = () => {
      console.log('WebSocket connected');
      setConnected(true);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received message:', data);
      // Only add the message if it's not from the current user
      if (data.sent_by_username !== currentUsername) {
        setMessages(prevMessages => [...prevMessages, {
          uuid: data.uuid,
          text: data.content,
          sender: data.sent_by_username,
          timestamp: data.timestamp,
          type: data.attachment ? "attachment" : "text",
          attachment: data.attachment
        }]);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      setConnected(false);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    wsRef.current = socket;

    return () => {
      socket.close();
    };
  }, [forumId, selectedGroup, token, currentUsername]);

  const fetchPreviousMessages = useCallback(async () => {
    if (!selectedGroup || !token || !forumId) return;

    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.staging.sensebod.com/api/v1/groups/message/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const forumMessages = data.filter(msg => msg.forum.id === forumId);
        const sortedMessages = forumMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        const formattedMessages = sortedMessages.map(msg => ({
          uuid: msg.uuid,
          text: msg.content,
          sender: msg.sent_by.username,
          timestamp: msg.timestamp,
          type: msg.attachment ? "attachment" : "text",
          attachment: msg.attachment
        }));
        setMessages(formattedMessages);
      } else {
        console.error("Failed to fetch messages:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedGroup, token, forumId]);

  useEffect(() => {
    if (selectedGroup) {
      fetchPreviousMessages();
      fetchForumData();
    }
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [selectedGroup, fetchPreviousMessages]);

  useEffect(() => {
    if (forumId || selectedGroup) {
      const cleanup = connectWebSocket();
      return cleanup;
    }
  }, [forumId, selectedGroup, connectWebSocket]);

  const fetchForumData = async () => {
    try {
      const response = await fetch(
        "https://api.staging.sensebod.com/api/v1/groups/discussion_forum/",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const matchingForum = data.find((forum) => forum.group.uuid === selectedGroup.uuid);

        if (matchingForum) {
          setForumId(matchingForum.id);
        } else {
          console.error("No matching forum found for the selected group");
          setForumId(null);
        }
      } else {
        console.error("Failed to fetch forum data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching forum data:", error);
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      let messageToSend;
      if (filePreview) {
        messageToSend = JSON.stringify({
          message: message || "File upload",
          attachment: filePreview
        });
      } else if (message.trim() !== "") {
        messageToSend = JSON.stringify({
          message: message
        });
      }

      if (messageToSend) {
        wsRef.current.send(messageToSend);

        setMessages(prevMessages => [...prevMessages, {
          uuid: Date.now().toString(),
          text: message,
          sender: currentUsername,
          timestamp: new Date().toISOString(),
          type: filePreview ? "attachment" : "text",
          attachment: filePreview
        }]);
      }

      setMessage("");
      setFilePreview(null);
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        if (file.type.startsWith('image/')) {
          const resizedBlob = await resizeImage(file, MAX_IMAGE_SIZE);
          const imageUrl = URL.createObjectURL(resizedBlob);
          const originalImageUrl = URL.createObjectURL(file);
          setFilePreview({
            url: imageUrl,
            originalUrl: originalImageUrl,
            name: file.name,
            type: file.type
          });
        } else {
          const fileUrl = URL.createObjectURL(file);
          setFilePreview({ url: fileUrl, name: file.name, type: file.type });
        }
      } catch (error) {
        console.error("Error processing file:", error);
        alert("There was an error processing the file. Please try again.");
      }
    } else {
      alert("Please select a valid file.");
    }
  };

  const handleCancelFile = () => {
    setFilePreview(null);
  };

  const renderFilePreview = () => {
    if (!filePreview) return null;

    if (filePreview.type.startsWith('image/')) {
      return (
        <div className="absolute bottom-[64px] left-[20px] right-[20px] bg-white p-4 border border-gray-300 rounded-t-lg">
          <img src={filePreview.url} alt="Preview" className="max-w-full h-auto mb-2" />
          <button onClick={handleCancelFile} className="px-4 py-2 bg-blue-gray-60 rounded">Cancel</button>
        </div>
      );
    } else {
      return (
        <div className="absolute bottom-[64px] left-[20px] right-[20px] bg-white p-4 border border-gray-300 rounded-t-lg">
          <p className="mb-2">File: {filePreview.name}</p>
          <button onClick={handleCancelFile} className="px-4 py-2 bg-blue-gray-60 rounded">Cancel</button>
        </div>
      );
    }
  };

  const handleImageClick = (imageUrl) => {
    setFullSizeImage(imageUrl);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const generateColor = (username) => {
    let hash = 0;
    for (let i = 0; i < username.length; i++) {
      hash = username.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const senderColors = useMemo(() => {
    const colors = {};
    messages.forEach(msg => {
      if (!colors[msg.sender]) {
        colors[msg.sender] = generateColor(msg.sender);
      }
    });
    return colors;
  }, [messages]);

  const renderMessage = (msg, index, messages) => {
    const isCurrentUser = msg.sender === currentUsername;
    const showSenderName = !isCurrentUser && (index === 0 || messages[index - 1].sender !== msg.sender);
    const senderColor = senderColors[msg.sender];

    return (
      <div key={msg.uuid} className={`mb-1 flex ${isCurrentUser ? "justify-end" : "justify-start"}`}>
        <div className={`relative max-w-[70%] ${isCurrentUser ? "bg-[#1e3a5f]" : "bg-[#f0f0f0]"} rounded-lg px-3 py-2`}>
          {showSenderName && (
            <div className="text-[13px] font-medium mb-1" style={{ color: senderColor }}>
              {msg.sender}
            </div>
          )}
          <div className="flex items-end justify-between">
            <p className={`text-[14.2px] leading-[19px] mr-2 ${isCurrentUser ? "text-white" : "text-[#0c092a]"}`}>
              {msg.text}
            </p>
            <div className={`text-[11px] flex-shrink-0 ${isCurrentUser ? "text-[#ffa500]" : "text-[#858494]"}`}>
              {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </div>
          </div>
          {msg.type === "attachment" && (
            <div className="mt-1">
              <a href={msg.attachment} target="_blank" rel="noopener noreferrer" className="text-blue-300 underline text-[14.2px]">
                Attachment
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  const groupMessagesByDate = (messages) => {
    const grouped = {};
    messages.forEach(msg => {
      const date = new Date(msg.timestamp).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(msg);
    });
    return grouped;
  };

  const renderDateSeparator = (date) => (
    <div className="flex items-center justify-center my-4 relative">
      <div className="bg-gray-300 h-[1px] w-full absolute"></div>
      <div className="bg-white px-4 z-10 relative">
        <div className="bg-gray-500 text-sm text-white px-3 py-1 rounded-[10%]">
          {date}
        </div>
      </div>
    </div>
  );

  const renderGroupedMessages = () => {
    const groupedMessages = groupMessagesByDate(messages);
    return Object.entries(groupedMessages).map(([date, msgs]) => (
      <React.Fragment key={date}>
        {renderDateSeparator(date)}
        {msgs.map((msg, index) => renderMessage(msg, index, msgs))}
      </React.Fragment>
    ));
  };

  if (!selectedGroup) {
    return (
      <div className="p-4">
        <h2>No Group Selected</h2>
        <p>Please select a group from the left to see details here.</p>
      </div>
    );
  }

  return (
    <div className={`absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] text-left text-[15px] text-[#0c092a] font-poppins ${className}`}>
      <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-sm bg-default-white border-[#b9b9b9] border-[0.3px] border-solid box-border" />
      <div className="absolute top-[18px] left-[27px] w-[183px] h-[36px]">
        <div className="absolute top-[0px] left-[49px] w-[170px] h-[36px]">
          <div className="absolute top-[0px] left-[0px] leading-[150%] font-medium">
            {selectedGroup.name}
          </div>
          <div className="absolute top-[21px] left-[1px] text-[11px] leading-[140%] text-[#858494]">
            {selectedGroup.creator}
          </div>
        </div>
        <img
          className="absolute h-[88.89%] w-[17.49%] top-[0%] right-[82.51%] bottom-[11.11%] left-[0%] rounded-[50%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src={selectedGroup.avatar || "/group/group.png"}
        />
      </div>
      <img
        className="absolute h-[2.89%] w-[2.75%] top-[3.85%] right-[2.45%] bottom-[93.26%] left-[94.8%] max-w-full overflow-hidden max-h-full cursor-pointer"
        alt=""
        src="/group/info.svg"
        onClick={handleInfoClick}
      />

      <div className="absolute top-[80px] left-[20px] right-[20px] bottom-[120px] overflow-y-scroll bg-[#fff] p-4 rounded">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <p>Loading messages...</p>
          </div>
        ) : (
          <>
            {renderGroupedMessages()}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      {renderFilePreview()}

      <div className={`absolute w-full bottom-[0] h-[64px] text-left text-base text-[#939699] font-poppins ${className}`}>
        <div className="absolute w-[94.19%] top-[-10%] right-[2.91%] left-[2.91%] bg-default-white border-solid box-border h-[64px] flex flex-row items-center justify-start py-[12px] pl-[0px] pr-[16px] gap-[14px]">
          <div className="flex-1 relative h-[40px]">
            <div className="absolute top-[0px] left-[0px] flex flex-row items-center justify-start gap-[8px]">
              <img
                className="w-[24px] relative h-[24px] overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/group/plus.svg"
                onClick={handleFileClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                accept="image/*,.pdf,.doc,.docx,.txt"
                style={{ display: 'none' }}
              />
              <img
                className="w-[40px] relative rounded-81xl h-[40px] overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/group/icnbutton-circle.svg"
                onClick={toggleEmojiPicker}
              />
            </div>
            <input
              type="text"
              className="absolute top-[10px] left-[84px] w-[80%] tracking-[-0.01em] leading-[20px] bg-transparent outline-none"
              placeholder={filePreview ? "Add a caption..." : "Type a message"}
              value={message}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-row items-start justify-start">
            <div className="w-[40px] relative rounded-81xl h-[40px] overflow-hidden shrink-0 cursor-pointer" onClick={handleSendMessage}>
              <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[4px] bg-buttonselect" />
              <img
                className="absolute top-[calc(50%_-_10px)] left-[calc(50%_-_10px)] w-[20px] h-[20px] overflow-hidden"
                alt="Send"
                src="/group/send.svg"
              />
            </div>
          </div>
        </div>
        <img
          className="absolute h-[1.56%] w-full top-[-10%] right-[0%] bottom-[98.44%] left-[0%] max-w-full overflow-hidden max-h-full"
          alt=""
          src="/group/divider.svg"
        />
      </div>

      {showEmojiPicker && (
        <div className="absolute bottom-[64px] left-[0px] z-10">
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
      )}

      {fullSizeImage && (
        <ImageModal
          src={fullSizeImage}
          alt="Full size image"
          onClose={() => setFullSizeImage(null)}
        />
      )}

      <img
        className="absolute h-[0.16%] w-full top-[10.59%] right-[0%] bottom-[89.25%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
        alt=""
        src="/group/divider1.svg"
      />
    </div>
  );
};

RightSide.propTypes = {
  className: PropTypes.string,
  selectedGroup: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    creation_date: PropTypes.string,
    avatar: PropTypes.string,
  }),
  handleInfoClick: PropTypes.func.isRequired,
};

export default RightSide;