import { useMemo } from "react";
import PropTypes from "prop-types";

const GroupComponent = ({
  className = "",
  propTop,
  icon,
  propWidth,
  propOverflow,
  awardsWon,
  propTop1,
  prop,
  courseEnrolled,
  propLeft,
  prop1,
  icon1,
}) => {
  const groupDiv2Style = useMemo(() => {
    return {
      top: propTop,
    };
  }, [propTop]);

  const icon1Style = useMemo(() => {
    return {
      width: propWidth,
      overflow: propOverflow,
    };
  }, [propWidth, propOverflow]);

  const awardsWonStyle = useMemo(() => {
    return {
      top: propTop1,
    };
  }, [propTop1]);

  const courseEnrolledStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  return (
    <div
      className={`absolute top-[0px] left-[0px] w-[295px] h-[96px] text-left text-sm text-default-white font-[Rubik] ${className}`}
      style={groupDiv2Style}
    >
      <div className="absolute top-[0px] left-[155px] w-[140px] h-[96px]">
        <div className="absolute top-[0px] left-[0px] rounded-xl bg-buttonselect w-[140px] h-[96px]" />
        <img
          className="absolute top-[16px] left-[100px] w-[24px] h-[24px] overflow-hidden"
          alt=""
          src={icon}
          style={icon1Style}
        />
        <div
          className="absolute top-[64px] left-[15px] leading-[140%]"
          style={awardsWonStyle}
        >
          {awardsWon}
        </div>
        <b className="absolute top-[16px] left-[16px] text-[32px] leading-[150%]">
          {prop}
        </b>
      </div>
      <div className="absolute top-[0px] left-[0px] w-[140px] h-[96px] text-xs text-[#0c092a] font-poppins">
        <div className="absolute top-[0px] left-[0px] rounded-xl bg-default-white w-[140px] h-[96px]" />
        <div
          className="absolute top-[64px] left-[17px] leading-[140%]"
          style={courseEnrolledStyle}
        >
          {courseEnrolled}
        </div>
        <b className="absolute top-[16px] left-[16px] text-[32px] leading-[150%] font-[Rubik]">
          {prop1}
        </b>
        <img
          className="absolute top-[16px] left-[100px] w-[24px] h-[24px] overflow-hidden"
          alt=""
          src={icon1}
        />
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  awardsWon: PropTypes.string,
  prop: PropTypes.string,
  courseEnrolled: PropTypes.string,
  prop1: PropTypes.string,
  icon1: PropTypes.string,

  /** Style props */
  propTop: PropTypes.any,
  propWidth: PropTypes.any,
  propOverflow: PropTypes.any,
  propTop1: PropTypes.any,
  propLeft: PropTypes.any,
};

export default GroupComponent;
