import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";
import axios from "axios";

const PreferencesScreen5 = () => {
  const navigate = useNavigate();
  const [schoolInput, setSchoolInput] = useState("");
  const [schools, setSchools] = useState([]); // To hold the fetched school data
  const [filteredSchools, setFilteredSchools] = useState([]); // To hold filtered schools based on input
  const [showDropdown, setShowDropdown] = useState(false);

  const onButtonContainerClick = useCallback(() => {
    navigate("/8-preferencesscreen");
  }, [navigate]);

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous screen
  };
  const handleSkip = () => {
    navigate("/8-preferencesscreen");
  }

  // Fetch schools when the component mounts
  useEffect(() => {
    const fetchSchools = async () => {
      const districtName = localStorage.getItem("district_name");
      const regionId = localStorage.getItem("region_id");

      if (!districtName || !regionId) return; // Do not fetch if district or region data is missing

      try {
        const response = await axios.get(`https://api.staging.sensebod.com/api/v1/schools/?district_name=${districtName}&region_id=${regionId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        console.log("School", response.data);
        setSchools(response.data); // Set the fetched schools data to state
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  const handleSchoolInputChange = (e) => {
    const input = e.target.value;
    setSchoolInput(input);

    if (input) {
      // Filter the schools based on the input
      const filtered = schools.filter((school) =>
        school.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredSchools(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const storeSelectedSchool = (selectedSchool) => {
    const country = {
      country_short_code: localStorage.getItem("country_short_code"),
      country: localStorage.getItem("country"),
    };
    const region = {
      region_name: localStorage.getItem("region_name"),
    };
    const district = {
      district_name: localStorage.getItem("district_name"),
    };

    const schoolData = {
      school: {
        school_name: selectedSchool,
        country,
        region,
        district,
      },
    };

    // Store the school data in localStorage
    localStorage.setItem("selectedSchoolData", JSON.stringify(schoolData));
    console.log("Stored school data:", schoolData);
  };

  const handleSchoolSelect = (school) => {
    setSchoolInput(school);
    setShowDropdown(false);
    storeSelectedSchool(school); // Store the selected school data in localStorage
  };

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-[#686b6d] font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="137px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center text-black">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium whitespace-pre-wrap flex items-center justify-center w-[148px] h-[84px]">
          Tell me where your school is so I can tailor content specifically for
          you.
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="30%"
          rectangleDivRight="70%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          3 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[730px] left-[calc(50%_-_15px)] w-[30px] h-[16px] text-[#c3beb5] font-poppins">
        <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium cursor-pointer" onClick={handleSkip}>
          Skip
        </div>
      </div>
      <div className="absolute top-[361px] left-[calc(50%_-_185px)] w-[370px] h-[138px] text-left text-xs">
        <div className="absolute top-[0px] left-[0px] w-[370px] flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch flex flex-row items-center justify-start">
              <div className="relative tracking-[-0.01em] leading-[20px]">
                School
              </div>
            </div>
            <div className="self-stretch rounded-[12px] border-[#2f60f5] border-[1px] border-solid box-border h-[48px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-[12px] px-[16px] text-black">
              <div className="flex flex-row items-center justify-start gap-[8px]">
                <input
                  type="text"
                  value={schoolInput}
                  onChange={handleSchoolInputChange}
                  placeholder="Start typing to search..."
                  className="border-none w-[330px] bg-default-white focus:outline-none text-black"
                />
              </div>
            </div>
          </div>
          {showDropdown && (
            <div className="absolute top-[75px] left-0 w-full rounded-[12px] border-[#ebebeb] border-[1px] border-solid box-border bg-default-white overflow-hidden">
              {filteredSchools.map((school, index) => (
                <div
                  key={index}
                  className="py-2 px-4 hover:bg-gray-100 hover:text-default-white cursor-pointer"
                  onClick={() => handleSchoolSelect(school)}
                >
                  {school}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreferencesScreen5;
