import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";
import Rightside from "../../components/profile/Rightside";
import StatisticsBoard from "../../components/profile/StatisticsBoard";
import ReportBoard from "../../components/profile/ReportBoard";

const Statistics = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in by checking for the accessToken in localStorage
        const accessToken = localStorage.getItem("accessToken");

        // If no accessToken is found, redirect to the login page
        if (!accessToken) {
            navigate("/login"); // Replace with the actual path to your login page
        }
    }, [navigate]);

    return (
        <div className="w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl text-default-white font-poppins">
            {/* <GroupComponent emojiWavingHandSign="/-emoji-waving-hand-sign1@2x.png" /> */}
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>
            <div className="absolute h-[420px] w-[681px] top-[193px] left-[284px] shadow-[0px_1px_4px_rgba(0,_0,_0,_0.2)] rounded-xl bg-background-main">
                <b className="absolute top-[29px] right-[280px] text-5xl leading-[120%]">
                    Statistics
                </b>

                <StatisticsBoard />
                

            </div>

            <div className="absolute top-[609px] pb-[100px] left-[282px] w-[642px] h-[397px]">
            <ReportBoard />
      </div>


            <Rightside />


            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
            <MainNavigation
                homeClass=""
                learningClass=""
                mentorClass=""
                groupsClass=""
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />
        </div>
    );
};

export default Statistics;
