
import PropTypes from "prop-types";
import LeaderboardPagination from "./LeaderboardPagination";
import TopStuSchool from "./TopStuSchool";
import StuSchoolList from "./StuSchoolList";
import { useNavigate } from 'react-router-dom';


const students = [
  {
    position: 1,
    name: 'Grace John',
    points: 1301,
    avatar: '/profile/avatar2@2x.png',
    rectangleSrc: '/profile/rectangle-942.svg',
    school: 'SLGS',
  },
  {
    position: 2,
    name: 'Micheal Bah',
    points: 1210,
    avatar: '/profile/avatar1@2x.png',
    rectangleSrc: '/profile/rectangle-941.svg',
    school: 'POW',
  },
  {
    position: 3,
    name: 'Leon Cole',
    points: 870,
    avatar: '/profile/avatar@2x.png',
    rectangleSrc: '/profile/rectangle-94.svg',
    school: 'AWMS',
  },
  {
    position: 4,
    name: 'John Doe',
    points: 700,
    avatar: '/profile/avatar3@2x.png',
    school: 'SLGS',
  },
  {
    position: 5,
    name: 'Jane Smith',
    points: 690,
    avatar: '/profile/avatar4@2x.png',
    school: 'POW',
  },
  {
    position: 6,
    name: 'Mark Johnson',
    points: 689,
    avatar: '/profile/avatar5@2x.png',
    school: 'MGHS',
  },
];

const SchoolStudentLeaderboard = ({ className = "" }) => {
  const navigate = useNavigate(); // Initialize navigate

  // Handlers for navigation
  const goToNationalLeaderboard = () => navigate('/national-leaderboard');
  const goToSchoolLeaderboard = () => navigate('/school-student-leaderboard');
  const goToTopSchoolsLeaderboard = () => navigate('/school-leaderboard');

  return (
    <div
      className={`absolute top-[0px] left-[0px] w-[615px] h-[990px] text-center text-[40px] text-default-white font-poppins ${className}`}
    >
      <div className="absolute top-[-1px] left-[-1px] rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border w-[617px] h-[926px]" />
      <div className="absolute top-[35px] left-[65px] w-[485px] h-[50px] text-left text-xl">
        <div className="absolute top-[0px] left-[0px] rounded-[12px] bg-[#1e2237] w-[485px] h-[50px]" />
        <div
          onClick={goToNationalLeaderboard}
          className="absolute top-[12px] left-[34px] font-medium cursor-pointer z-10"
        >
          National
        </div>
        <div
          onClick={goToSchoolLeaderboard}
          className="absolute top-[12px] left-[195px] font-medium cursor-pointer z-10"
        >
          School
        </div>
        <div
          onClick={goToTopSchoolsLeaderboard}
          className="absolute top-[12px] left-[338px] font-medium cursor-pointer z-10"
        >
          Top Schools
        </div>
        <div className="absolute top-[47px] left-[190px] rounded-[10px] bg-primary-gold w-[85.1px] h-[3px]" />
      </div>

      {students.map((student) => (
        <TopStuSchool key={student.position} student={student} />
      ))}

      <StuSchoolList students={students} />

      <LeaderboardPagination />
    </div>
  );
};

SchoolStudentLeaderboard.propTypes = {
  className: PropTypes.string,
};

export default SchoolStudentLeaderboard;
