import React, { useState } from 'react';
import GroupComponent from './GroupComponent';
import PropTypes from 'prop-types';
import SummaryCalender from "./SummaryCalender"

const ReportBoard = ({ className = '' }) => {
  const [date, setDate] = useState(new Date());

  return (
    <div
      className={`absolute top-[0px] left-[calc(50%_-_321px)] w-[642px] h-[397px] text-left text-sm text-default-white font-[Rubik] ${className}`}
    >
      
          <SummaryCalender
          />

      <div className="absolute top-[71px] left-[387px] w-[295px] h-[310px]">
        <GroupComponent
          icon="/profile/icon-2.svg"
          awardsWon="Awards Won"
          prop="2"
          courseEnrolled="Course Enrolled"
          prop1="1"
          icon1="/profile/icon-1.svg"
        />
        <GroupComponent
          propTop="107px"
          icon="/profile/icon-4.svg"
          propWidth="24px"
          propOverflow="hidden"
          awardsWon="Day Streak"
          propTop1="60px"
          prop="1"
          courseEnrolled="Quizzes Completed"
          propLeft="13px"
          prop1="0"
          icon1="/profile/icon-3.svg"
        />
        <GroupComponent
          propTop="214px"
          icon="/profile/vector.svg"
          propWidth="15.5px"
          propOverflow="unset"
          awardsWon="Task completed"
          propTop1="60px"
          prop="0"
          courseEnrolled="Time Spent"
          propLeft="13px"
          prop1="2hr"
          icon1="/profile/icon-5.svg"
        />
      </div>
    </div>
  );
};

ReportBoard.propTypes = {
  className: PropTypes.string,
};

export default ReportBoard;
