import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Menu, X, LogOut } from 'lucide-react';

const MainNavigation = ({
  className = "",
  homeClass = "",
  learningClass = "",
  mentorClass = "",
  groupsClass = "",
  profileClass = "",
}) => {
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 750);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch("https://api.staging.sensebod.com/api/v1/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (response.ok) {
        // Logout successful
        localStorage.clear();
        navigate("/");
      } else {
        // Handle logout failure
        console.error("Logout failed:", await response.text());
        // Optionally, you can still clear local storage and redirect
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      // In case of network errors, still clear local storage and redirect
      localStorage.clear();
      navigate("/");
    }
  };

  return (
    <>
      {/* Hamburger menu button (always visible on mobile) */}
      <button 
        className={`fixed top-4 z-[60] p-2 cursor-pointer rounded-md block md:hidden transition-all duration-300 ${
          isMenuOpen 
            ? 'left-[13rem] bg-white text-background-main' 
            : 'left-4 bg-background-main text-white'
        }`}
        onClick={toggleMenu}
      >
        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <div
        className={`fixed top-0 left-0 w-[255px] h-full bg-background-main text-left text-[0.813rem] text-[#777777] font-poppins z-50 ${className} transition-transform duration-300 ease-in-out ${
          isMobile && !isMenuOpen ? '-translate-x-full' : 'translate-x-0'
        } flex flex-col justify-between`}
      >
        <div>
          {/* Logo */}
          <img
            className="w-[calc(100% - 62px)] mt-[20px] pl-[10%] mx-auto max-w-full overflow-hidden h-auto"
            alt="Logo"
            src={`${baseUrl}/dashboard/sensebody-secondary-logo--1-1.svg`}
          />

          {/* Enhanced Divider line */}
          <div className="relative w-full h-[2px] my-6">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-400 to-transparent opacity-50"></div>
          </div>

          {/* Navigation items */}
          <nav className="mt-8">
            {/* Home section */}
            <div
              className={`w-full h-[52px] text-sm ${homeClass} cursor-pointer flex items-center px-4 ${
                homeClass.includes('bg-[#2563EB]') ? 'text-white' : ''
              }`}
              onClick={() => navigate("/")}
            >
              <img
                className="w-[32px] h-[32px] object-cover mr-4"
                alt="Home Icon"
                src={`${baseUrl}/dashboard/icon.svg`}
              />
              <b className="tracking-[0.8px] leading-[25px] uppercase">Home</b>
            </div>

            {/* Learning section */}
            <div
              className={`w-full h-[52px] ${learningClass} cursor-pointer flex items-center px-4 ${
                learningClass.includes('bg-[#2563EB]') ? 'text-white' : ''
              }`}
              onClick={() => navigate("/course/learning-home")}
            >
              <img
                className="w-[32px] h-[32px] object-cover mr-4"
                alt="Learning Icon"
                src={`${baseUrl}/dashboard/iconcam2book-1@2x.png`}
              />
              <b className="tracking-[0.8px] leading-[25px] uppercase">Learning</b>
            </div>

            {/* Mentor section */}
            <div
              className={`w-full h-[52px] ${mentorClass} cursor-pointer flex items-center px-4 ${
                mentorClass.includes('bg-[#2563EB]') ? 'text-white' : ''
              }`}
              onClick={() => navigate("/mentor")}
            >
              <img
                className="w-[32px] h-[32px] object-cover mr-4"
                alt="Mentor Icon"
                src={`${baseUrl}/dashboard/lamp@2x.png`}
              />
              <b className="tracking-[0.8px] leading-[25px] uppercase">Mentor</b>
            </div>

            {/* Groups section */}
            <div
              className={`w-full h-[52px] ${groupsClass} cursor-pointer flex items-center px-4 ${
                groupsClass.includes('bg-[#2563EB]') ? 'text-white' : ''
              }`}
              onClick={() => navigate("/groups")}
            >
              <img
                className="w-[32px] h-[32px] object-cover mr-4"
                alt="Groups Icon"
                src={`${baseUrl}/dashboard/iconcam1calandar@2x.png`}
              />
              <b className="tracking-[0.8px] leading-[25px] uppercase">Groups</b>
            </div>

            {/* Profile section */}
            <div
              className={`w-full h-[52px] text-sm ${profileClass} cursor-pointer flex items-center px-4 ${
                profileClass.includes('bg-[#2563EB]') ? 'text-white' : ''
              }`}
              onClick={() => navigate("/national-leaderboard")}
            >
              <img
                className="w-[32px] h-[32px] object-cover mr-4"
                alt="Profile Icon"
                src={`${baseUrl}/dashboard/settingfrontcolor@2x.png`}
              />
              <b className="tracking-[0.8px] leading-[25px] uppercase">Profile</b>
            </div>
          </nav>
        </div>

        {/* Logout button for mobile */}
        {isMobile && (
          <div 
            className="w-[80%] h-[52px] text-sm cursor-pointer flex items-center px-4 text-white bg-red-500 hover:bg-red-600 transition-colors duration-300"
            onClick={handleLogout}
          >
            <LogOut className="w-[32px] h-[32px] mr-4" />
            <b className="tracking-[0.8px] leading-[25px] uppercase">Logout</b>
          </div>
        )}
      </div>
    </>
  );
};

MainNavigation.propTypes = {
  className: PropTypes.string,
  homeClass: PropTypes.string,
  learningClass: PropTypes.string,
  mentorClass: PropTypes.string,
  groupsClass: PropTypes.string,
  profileClass: PropTypes.string,
};

export default MainNavigation;