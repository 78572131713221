import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Progress7 from "../../components/Progress7";

const PreferencesScreen7 = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState("Select Grade");
  const [grades, setGrades] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleGradeSelect = (grade) => {
    setSelectedGrade(grade.display_name); // Use display_name for UI
    setIsDropdownOpen(false);
  };

  const fetchGrades = async () => {
    try {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      const response = await axios.get("https://api.staging.sensebod.com/api/v1/preference/current-grade/", {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
      });
      setGrades(response.data); // Assuming the API returns an array of grades
      console.log("Grades", response.data);
    } catch (error) {
      console.error("Error fetching grades:", error);
    }
  };

  useEffect(() => {
    fetchGrades();
  }, []); // Fetch grades when the component mounts

  const onButtonContainerClick = useCallback(() => {
    // Retrieve the existing preferencesData from localStorage
    const existingPreferences = JSON.parse(localStorage.getItem("preferencesData")) || {};

    // Add the selected grade to the preferencesData
    const updatedPreferences = {
      ...existingPreferences,
      current_grade: selectedGrade,
    };

    // Store the updated preferencesData back in localStorage
    localStorage.setItem("preferencesData", JSON.stringify(updatedPreferences));

    // Navigate to the next screen
    navigate("/13-preferencesscreen");
  }, [navigate, selectedGrade]);

  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous screen
  };

  

  return (
    <div className="w-full relative bg-default-white h-[100vh] overflow-hidden text-right text-sm text-black font-poppins">
      <img
        className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
        alt=""
        src="/o11@2x.png"
      />
      <img
        className="absolute top-[442px] left-[calc(50%_-_950px)] w-[526.8px] h-[526.8px] object-contain"
        alt=""
        src="/easystem-4-1@2x.png"
      />
      <img
        className="absolute top-[-325px] left-[-68px] w-[1933.8px] h-[1738.8px] object-contain"
        alt=""
        src="/preference.svg"
      />
      <img
        className="absolute top-[-120px] left-[calc(50%_+_450px)] w-[562px] h-[562px] object-contain"
        alt=""
        src="/easystem-4-22@2x.png"
      />
      <Button
        onButtonContainerClick={onButtonContainerClick}
        buttonWidth="370px"
        buttonPosition="absolute"
        buttonBackgroundColor="#055db2"
        buttonPadding="16px 133px"
        buttonBottom="107px"
        buttonLeft="calc(50% - 185px)"
        buttonTop="unset"
        button="Next"
        buttonFontSize="16px"
        buttonFontWeight="bold"
        buttonFontFamily="Poppins"
      />
      <div className="absolute top-[106px] left-[500px] w-[185px] h-[105px] text-center">
        <img
          className="absolute top-[0px] left-[0px] rounded-[3px] w-[185px] h-[105px]"
          alt=""
          src="/vector-1.svg"
        />
        <div className="absolute top-[10px] left-[29px] leading-[150%] font-medium flex items-center justify-center w-[148px] h-[84px]">
          {`Tell me your grade in school so I can tailor content specifically for your level.`}
        </div>
      </div>
      <img
        className="absolute h-[18.03%] w-[11.72%] top-[8%] right-[80.08%] bottom-[71.51%] left-[15%] max-w-full overflow-hidden max-h-full object-cover"
        alt=""
        src="/download-1-1@2x.png"
      />
      <div className="absolute top-[calc(50%_-_450px)] left-[calc(50%_-_385px)] w-[921px] h-[53px] text-right text-buttonselect font-[Francy] mh800:top-[calc(50%_-_350px)] mh900:top-[calc(50%_-_390px)]">
        <Progress7
          progressWidth="871px"
          progressPosition="absolute"
          progressHeight="21px"
          progressTop="calc(50% - 21.5px)"
          progressLeft="calc(50% - 410.5px)"
          rectangleDivBackgroundColor="rgba(5, 93, 178, 0.1)"
          rectangleDivWidth="80%"
          rectangleDivRight="20%"
          rectangleDivBackgroundColor1="#f09a19"
        />
        <div className="absolute w-[11.16%] top-[60.38%] left-[88.82%] leading-[150%] flex items-center">
          8 of 10
        </div>
        <img
          onClick={handleGoBack} // Go back when this icon is clicked
          className="absolute h-[60.38%] w-[3.47%] top-[0%] right-[96.53%] bottom-[39.62%] left-[0%] max-w-full overflow-hidden max-h-full cursor-pointer"
          alt="Go Back"
          src="/icon.svg"
        />
      </div>
      <div className="absolute top-[calc(50%_-_28px)] left-[calc(50%_-_185px)] w-[370px]">
        <div
          className="relative rounded-[12px] bg-[rgba(15,137,255,0.2)] border-[#efeefc] border-[2px] border-solid box-border h-[56px] text-left text-[#0c092a] flex items-center px-4 cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="w-[27.84%] leading-[150%] font-medium">
            {selectedGrade}
          </div>
          <img
            className={`absolute right-4 transform transition-transform ${
              isDropdownOpen ? "rotate-180" : ""
            }`}
            alt=""
            src="/icon1.svg"
          />
        </div>
        {isDropdownOpen && (
          <div className="absolute z-10 mt-2 w-full bg-white rounded-lg shadow-lg">
            {grades.map((grade, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                onClick={() => handleGradeSelect(grade)}
              >
                {grade.display_name} {/* Use display_name for rendering */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreferencesScreen7;
