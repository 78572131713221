import Rightside from "../../components/profile/Rightside";
import PropTypes from "prop-types";
import MainNavigation from "../../components/MainNavigation";
import NavBar from "../../components/NavBar";

import { useNavigate } from 'react-router-dom';

const SchoolsLeaderboard = ({ className = "" }) => {
    const navigate = useNavigate(); // Initialize navigate

    // Handlers for navigation
    const goToNationalLeaderboard = () => navigate('/national-leaderboard');
    const goToSchoolLeaderboard = () => navigate('/school-student-leaderboard');
    const goToTopSchoolsLeaderboard = () => navigate('/school-leaderboard');

    return (
        <div
            className={`w-full relative bg-[#fbf1f1] min-h-screen overflow-auto text-left text-xl font-poppins ${className}`}
        >
            <img
                className="absolute top-[-317px] left-[-399px] w-[1276.7px] h-[1305.2px] object-contain hidden"
                alt=""
                src="/profile/o2@2x.png"
            />
            <div className="absolute top-[116px] left-[calc(50%_-_59px)] text-[32px] leading-[150%] font-semibold text-[#0d0c0c]">
                Profile
            </div>
            <Rightside />
            <div className="absolute top-[193px] left-[284px] w-[615px] h-[990px] pb-[100px] text-sm text-[#202224]">
                <div className="absolute top-[0px] left-[0px] w-[615px] h-[990px]">
                    <div className="absolute top-[-1px] left-[-1px] rounded-xl bg-background-main border-[#efe7e7] border-[1px] border-solid box-border w-[617px] h-[926px]" />
                    <div className="absolute top-[35px] left-[65px] w-[485px] h-[50px] text-xl text-default-white">
                        <div className="absolute top-[0px] left-[0px] rounded-[12px] bg-[#1e2237] w-[485px] h-[50px]" />
                        <div
                            onClick={goToNationalLeaderboard}
                            className="absolute top-[12px] left-[34px] font-medium cursor-pointer z-10"
                        >
                            National
                        </div>
                        <div
                            onClick={goToSchoolLeaderboard}
                            className="absolute top-[12px] left-[195px] font-medium cursor-pointer z-10"
                        >
                            School
                        </div>
                        <div
                            onClick={goToTopSchoolsLeaderboard}
                            className="absolute top-[12px] left-[338px] font-medium cursor-pointer z-10"
                        >
                            Top Schools
                        </div>
                        <div className="absolute top-[47px] left-[337px] rounded-[10px] bg-primary-gold w-[122px] h-[3px]" />
                    </div>
                    <div className="absolute top-[935px] left-[76px] w-[460px] h-[55px] text-center text-[#10027e]">
                        <div className="absolute top-[0px] left-[41px] rounded-[10px] bg-default-white w-[376px] h-[55px]" />
                        <div className="absolute top-[11px] left-[76px] rounded-[10px] bg-background-main w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[123.6px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[171.2px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[218.7px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[266.3px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[313.9px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <div className="absolute top-[11px] left-[361.5px] rounded-[10px] bg-[#f1f0ff] w-[20px] h-[34.9px]" />
                        <img
                            className="absolute top-[15px] left-[430px] w-[30px] h-[28.5px] object-contain"
                            alt=""
                            src="/profile/group-29@2x.png"
                        />
                        <img
                            className="absolute top-[15px] left-[0px] w-[30px] h-[28.5px] object-contain"
                            alt=""
                            src="/profile/group-28@2x.png"
                        />
                        <div className="absolute top-[19px] left-[77px] text-default-white inline-block w-[16.6px] h-[29px]">
                            1
                        </div>
                        <div className="absolute top-[19px] left-[124.9px] inline-block w-[16.6px] h-[29px]">
                            2
                        </div>
                        <div className="absolute top-[19px] left-[172.9px] inline-block w-[16.6px] h-[29px]">
                            3
                        </div>
                        <div className="absolute top-[19px] left-[220.9px] inline-block w-[16.6px] h-[29px]">
                            4
                        </div>
                        <div className="absolute top-[19px] left-[268.9px] inline-block w-[16.6px] h-[29px]">
                            5
                        </div>
                        <div className="absolute top-[19px] left-[316.9px] inline-block w-[16.6px] h-[29px]">
                            6
                        </div>
                        <div className="absolute top-[19px] left-[364.9px] inline-block w-[16.6px] h-[29px]">
                            7
                        </div>
                    </div>
                    <div className="absolute h-[4.85%] w-[91.38%] top-[11.72%] right-[4.55%] bottom-[83.43%] left-[4.07%]">
                        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-[8px] bg-default-white border-[#d5d5d5] border-[0.6px] border-solid box-border" />
                        <b className="absolute w-[7.85%] top-[calc(50%_-_8px)] left-[6.41%] inline-block">
                            Rank
                        </b>
                        <b className="absolute w-[11.17%] top-[calc(50%_-_8px)] left-[22.6%] inline-block">
                            Schools
                        </b>
                        <b className="absolute w-[9.22%] top-[calc(50%_-_8px)] left-[84.43%] inline-block">
                            Badge
                        </b>
                        <b className="absolute top-[calc(50%_-_8px)] left-[60.5%]">
                            Points
                        </b>
                    </div>
                    <div className="absolute h-[74.65%] w-[91.38%] top-[17.07%] right-[4.55%] bottom-[8.28%] left-[4.07%]">
                        <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-default-white border-[#d5d5d5] border-[0.6px] border-solid box-border" />
                        <div className="absolute w-full top-[calc(50%_-_359.5px)] right-[0%] left-[0%] h-[728px]">
                            <div className="absolute w-full top-[calc(50%_-_364px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    1
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_7.5px)] left-[22.6%] font-semibold whitespace-pre-wrap inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    Prince of Wales, Kingtom
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/pow.png"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_-_303px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_19.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    Sierra Leone Grammar School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_10.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/slgs.svg"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_-_242px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    3
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_19.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    Annie Walsh Memorial School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/awms.svg"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_-_181px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    4
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_21.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    St Edwards Secondary School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/sess.svg"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_-_120px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    5
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_20.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    St Joseph Convent Secondary School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/sjcs.svg"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_-_59px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    6
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_20.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    Methodist Girls High School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/mghs.svg"
                                />
                            </div>
                            <div className="absolute w-full top-[calc(50%_+_2px)] right-[0%] left-[0%] h-[57px]">
                                <div className="absolute w-[1.07%] top-[calc(50%_-_6.5px)] left-[8.72%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    7
                                </div>
                                <img
                                    className="absolute h-[1.93%] w-full top-[98.07%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full mix-blend-normal"
                                    alt=""
                                    src="/profile/line-2.svg"
                                />
                                <div className="absolute w-[32.03%] top-[calc(50%_-_20.5px)] left-[22.6%] font-semibold inline-block opacity-[0.9] mix-blend-normal">
                                    Albert Academy Secondary School
                                </div>
                                <div className="absolute w-[6.94%] top-[calc(50%_-_6.5px)] left-[60.5%] font-semibold inline-block h-[22.3px] opacity-[0.9] mix-blend-normal">
                                    2,569
                                </div>
                                <img
                                    className="absolute h-[87.72%] w-[8.9%] top-[0%] right-[7.83%] bottom-[12.28%] left-[83.27%] max-w-full overflow-hidden max-h-full object-cover"
                                    alt=""
                                    src="/profile/aa.svg"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <a href="/aichat" className="fixed bottom-6 left-[285px] z-[10000]">
        <img
          className="w-[75px] h-[75px] object-contain cursor-pointer"
          alt="AI Chat"
          src="/home/AIChart.svg"
        />
      </a>
      <NavBar />
            <MainNavigation
                homeClass=""
                learningClass=""
                mentorClass=""
                groupsClass=""
                profileClass="left-[-29px] rounded-tl-[0px] rounded-tr-11xl rounded-br-11xl rounded-bl-[0px] bg-buttonselect border-buttonselect border-[2px] border-solid box-border text-default-white"
            />
        </div>
    );
};

SchoolsLeaderboard.propTypes = {
    className: PropTypes.string,
};

export default SchoolsLeaderboard;
